<template>
  <v-card
    flat
    tile
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-card>
</template>

<script>
  import { VCard } from 'vuetify/lib'
  export default {
    name: 'Card',
  }
/*   import { VCard } from 'vuetify/lib'

  export default {
    name: 'Card',

    extends: VCard,
  } */
</script>

