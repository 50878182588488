export default {
  greeting: 'イノテッククリニックへようこそ',
  chooseLanguage: '言語を選択してください',
  flag: 'jp',
  profile: 'プロフィール',
  logout: 'ログアウト',
  yes: 'はい',
  no: 'いや',
  new: 'New',
  delete: 'Delete',
  save: '保存する',
  query: 'Query',
  find: 'Find',
  cancel: 'キャンセル',
  language: '言語',
  createUser: 'Create User',
  createDate: 'Create Date',
  maintUser: 'Maint User',
  maintDate: 'Maint Date',
  waiting: 'Please waiting',
  search: 'サーチ',
  actions: '行動',
  itemsPerPageText: 'Rows per page:',
  itemsPerPageAllText: 'All',
  noData: 'No data available',
  searchNotFound: 'Your search for "{}" found no results.',
  languages: {
    english: 'English',
    thai: 'Thai',
    chineseSimplified: 'Chinese (Simplified)',
    chineseTraditional: 'Chinese (Traditional)',
    japanese: 'Japanese',
    korean: 'Korean',
  },
  waypoint: 'Waypoint',
  logicalWindow: 'Logical Window',
}
