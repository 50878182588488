<template>
  <div class="dropdown">
    <button>
      <flag :iso="$t('flag')" v-bind:squared="false"/>
        <!-- <span style="color:orange;"> {{ $t('chooseLanguage') }} </span> -->
    </button>
    <div class="dropdown-content">
      <a
        v-for="(lang, index) in languages"
        :key="`lang${index}`"
        @click="changeLanguage(lang)">
        <flag :iso="lang.flag" v-bind:squared="false" />
      {{ lang.title }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'languagePicker',
  data () {
    return {
    }
  },
  computed: {
    languages () {
      return [
        {
          title: this.$t('languages.english'),
          value: 'en',
          flag: 'us',
        },
        {
          title: this.$t('languages.thai'),
          value: 'th',
          flag: 'th',
        },
        /* {
          title: this.$t('languages.chineseSimplified'),
          value: 'zhHans',
          flag: 'cn',
        },
        {
          title: this.$t('languages.chineseTraditional'),
          value: 'zhHant',
          flag: 'cn',
        },
        {
          title: this.$t('languages.japanese'),
          value: 'jp',
          flag: 'jp',
        },
        {
          title: this.$t('languages.korean'),
          value: 'kr',
          flag: 'kr',
        }, */
      ]
    }
  },
  methods: {
    changeLanguage (lang) {
      this.$i18n.locale = lang.value
      this.$store.dispatch('language/setLanguage', lang.value)
      // this.languages[0].title = this.$t('languages.english')
      // this.languages[1].title = this.$t('languages.thai')

      this.$emit('selectLanguageEvent', lang.value)
    },
  }
}
</script>
<style>
.dropdown {
  float: right;
  position: relative;
  display: inline-block;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  right: 0;
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: rgb(0, 0, 0);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
