// utils/index.js

import Vue from 'vue'
import NoImageFound from '../assets/no_image_found.png'
import ImageWait from '../assets/MeeTeeMeeNgern/1000x420px/2.1.1000x42 px.jpg'
import axios from 'axios'

export const EventBus = new Vue()

export const toggleFullScreen = () => {
  let doc = window.document
  let docEl = doc.documentElement

  let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
  let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl)
  } else {
    cancelFullScreen.call(doc)
  }
}

export function convertNumberToTextEng (val) {
  var ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen']
  var tens = ['', '', 'twenty', 'thirty', 'fourty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']
  var sep = ['', ' thousand ', ' million ', ' billion ', ' trillion ', ' quadrillion ', ' quintillion ', ' sextillion ']
  var textBaht
  var arr = []
  var str = ''
  var i = 0

  if (val.length === 0) {
    textBaht = 'Please type a number into the text-box.'
  }

  val = parseInt(val, 10)
  if (isNaN(val)) {
    textBaht = 'Invalid input.'
  }

  while (val) {
    arr.push(val % 1000)
    val = parseInt(val / 1000, 10)
  }

  while (arr.length) {
    str = (function (a) {
      var x = Math.floor(a / 100)
      var y = Math.floor(a / 10) % 10
      var z = a % 10

      return (x > 0 ? ones[x] + ' hundred ' : '') +
             (y >= 2 ? tens[y] + ' ' + ones[z] : ones[10 * y + z])
    })(arr.shift()) + sep[i++] + str
  }

  textBaht = str
  return textBaht
}

export function isValidJwt (jwt) {
  if (!jwt || jwt.split('.').length < 3) {
    return false
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const exp = new Date(data.exp * 1000)
  const now = new Date()
  return now < exp
}

export function expireTimeJwt (jwt) {
  if (!jwt || jwt.split('.').length < 3) {
    return -1
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const exp = new Date(data.exp * 1000)
  const now = new Date()
  return exp - now
}

export function expireTimeTextJwt (jwt) {
  var diffMs = expireTimeJwt(jwt)
  var diffDays = Math.floor(diffMs / 86400000)
  var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
  var diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000)
  var diffSecs = Math.floor(((diffMs % 86400000) % 3600000) % 60000 / 1000)
  return diffDays + ' days, ' + diffHrs + ' hours, ' + diffMins + ' minutes,' + diffSecs + ' seconds'
}

export function dateToString (date) {
  function twoDigit (n) {
    return (n < 10 ? '0' : '') + n
  }

  return '' + date.getFullYear() + '-' + twoDigit(date.getMonth() + 1) + '-' + twoDigit(date.getDate()) + 'T' +
    twoDigit(date.getHours()) + ':' + twoDigit(date.getMinutes()) + ':' + twoDigit(date.getSeconds())
}

export function imageProfile (img) {
  if ((img === '') || (img === null)) {
    return NoImageFound
  } else {
    return `data:image/png;base64, ` + img
  }
}

export function imageWait (img) {
  if ((img === '') || (img === null)) {
    return ImageWait
  } else {
    return `data:image/png;base64, ` + img
  }
}

export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export function randomId () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return `_${Math.random()
      .toString(36)
      .substr(2, 9)}`
}

export function numberWithCommas (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function getDistanceBetweenPoints (lat1, lon1, lat2, lon2) {
  // var R = 6371
  var R = 6378.137
  var dLat = degreesToRadians(lat2 - lat1)
  var dLon = degreesToRadians(lon2 - lon1)
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2))
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d
}

export function degreesToRadians (Value) {
  return Value * Math.PI / 180
}

export async function asyncForEach (array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export async function UtilsAddVisitCountMenu (currentRouteName, refSkey) {
  console.log('UtilsAddVisitCountMenu', 'UtilsAddVisitCountMenu')
  await axios.post(process.env.VUE_APP_API + '/visit_count/addVisitCountMenu' + process.env.VUE_APP_DATABASE_TYPE, {
    win_name: currentRouteName,
    ref_skey: refSkey
  })
    .then(response => {
      if (response.data.isSuccess) {
        console.log(response.data, 'response.data')
      }
    })
    .catch(e => {
      console.log(e.message, '')
    })
}

export function isJson (str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}

export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
