export default {
  userMaintTitle: 'ผู้ใช้งาน',
  userID: 'ID ผู้ใช้',
  buttonNewUser: 'เพิ่มผู้ใช้',
  picture: 'รูปภาพ',
  firstName: 'ชื่อจริง',
  lastName: 'นามสกุล',
  employeeNo: 'รหัสพนักงาน',
  facilityCode: 'รหัส Facility',
}
