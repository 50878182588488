import en from './locales/en'
import th from './locales/th'
import jp from './locales/jp'
import kr from './locales/kr'
import zhHans from './locales/zhHans'
import zhHant from './locales/zhHant'
import userEn from './locales/en/user'
import userTh from './locales/th/user'
import navWindowEn from './locales/en/navWindow'
import navWindowTh from './locales/th/navWindow'
import navNodeEn from './locales/en/navNode'
import navNodeTh from './locales/th/navNode'
import labOrderOrderItemEn from './locales/en/labOrderOrderItem'
import labOrderOrderItemTh from './locales/th/labOrderOrderItem'
import loginEn from './locales/en/login'
import loginTh from './locales/th/login'
import pathologistListEn from './locales/en/pathologistList'
import pathologistListTh from './locales/th/pathologistList'
import webOrderEn from './locales/en/webOrder'
import webOrderTh from './locales/th/webOrder'
import pathoOnlineEn from './locales/en/pathoOnline'
import pathoOnlineTh from './locales/th/pathoOnline'
import pathoOnlineJp from './locales/jp/pathoOnline'
import pathoOnlineKr from './locales/kr/pathoOnline'
import pathoOnlineZhHans from './locales/zhHans/pathoOnline'
import pathoOnlineZhHant from './locales/zhHant/pathoOnline'
import InventoryEn from './locales/en/Inventory'
import InventoryTh from './locales/th/Inventory'
import MeeTeeMeeNgern1En from './locales/en/MeeTeeMeeNgern1'
import MeeTeeMeeNgern1Th from './locales/th/MeeTeeMeeNgern1'
import ArticlesMaintEn from './locales/en/ArticlesMaint'
import ArticlesMaintTh from './locales/th/ArticlesMaint'
import SubscribeMaintEn from './locales/en/SubscribeMaint'
import SubscribeMaintTh from './locales/th/SubscribeMaint'
import BranchGSBMaintEn from './locales/en/BranchGSBMaint'
import BranchGSBMaintTh from './locales/th/BranchGSBMaint'
import BranchTIPMaintEn from './locales/en/BranchTIPMaint'
import BranchTIPMaintTh from './locales/th/BranchTIPMaint'
import BranchBCPMaintEn from './locales/en/BranchBCPMaint'
import BranchBCPMaintTh from './locales/th/BranchBCPMaint'
import RegisterEn from './locales/en/Register'
import RegisterTh from './locales/th/Register'
import RegisterMaintEn from './locales/en/RegisterMaint'
import RegisterMaintTh from './locales/th/RegisterMaint'
import PromotionsMaintEn from './locales/en/PromotionsMaint'
import PromotionsMaintTh from './locales/th/PromotionsMaint'
import workWithUsMaintEn from './locales/en/workWithUsMaint'
import workWithUsMaintTh from './locales/th/workWithUsMaint'
import ProductMaintEn from './locales/en/ProductMaint'
import ProductMaintTh from './locales/th/ProductMaint'
import DocumentHeaderMaintEn from './locales/en/DocumentHeaderMaint'
import DocumentHeaderMaintTh from './locales/th/DocumentHeaderMaint'
import DocumentDetailMaintEn from './locales/en/DocumentDetailMaint'
import DocumentDetailMaintTh from './locales/th/DocumentDetailMaint'
import UploadMaintEn from './locales/en/UploadMaint'
import UploadMaintTh from './locales/th/UploadMaint'
import GuaranteeMaintEn from './locales/en/GuaranteeMaint'
import GuaranteeMaintTh from './locales/th/GuaranteeMaint'
import RejectMaintEn from './locales/en/RejectMaint'
import RejectMaintTh from './locales/th/RejectMaint'
import UserInformationMaintEn from './locales/en/UserInformationMaint'
import UserInformationMaintTh from './locales/th/UserInformationMaint'
import ReceiveChannelMaintEn from './locales/en/ReceiveChannelMaint'
import ReceiveChannelMaintTh from './locales/th/ReceiveChannelMaint'
import ConvenientTimeMaintEn from './locales/en/ConvenientTimeMaint'
import ConvenientTimeMaintTh from './locales/th/ConvenientTimeMaint'
import CreditLimitMaintEn from './locales/en/CreditLimitMaint'
import CreditLimitMaintTh from './locales/th/CreditLimitMaint'
// Object.keys(user).forEach(function (item) {
//   Object.assign(en, {item: user[item]})
// })

// _.merge(en, user)

// Object.assign(en, userEn)
// Object.assign(th, userTh)
// Object.assign(en, navWindowEn)
// Object.assign(th, navWindowTh)
// Object.assign(en, navNodeEn)
// Object.assign(th, navNodeTh)

en['navWindow'] = navWindowEn
th['navWindow'] = navWindowTh
en['user'] = userEn
th['user'] = userTh
en['navNode'] = navNodeEn
th['navNode'] = navNodeTh
en['labOrderOrderItem'] = labOrderOrderItemEn
th['labOrderOrderItem'] = labOrderOrderItemTh
en['login'] = loginEn
th['login'] = loginTh
en['pathologistList'] = pathologistListEn
th['pathologistList'] = pathologistListTh
en['webOrder'] = webOrderEn
th['webOrder'] = webOrderTh
en['pathoOnline'] = pathoOnlineEn
th['pathoOnline'] = pathoOnlineTh
jp['pathoOnline'] = pathoOnlineJp
kr['pathoOnline'] = pathoOnlineKr
zhHans['pathoOnline'] = pathoOnlineZhHans
zhHant['pathoOnline'] = pathoOnlineZhHant
en['Inventory'] = InventoryEn
th['Inventory'] = InventoryTh
en['MeeTeeMeeNgern1'] = MeeTeeMeeNgern1En
th['MeeTeeMeeNgern1'] = MeeTeeMeeNgern1Th
en['ArticlesMaint'] = ArticlesMaintEn
th['ArticlesMaint'] = ArticlesMaintTh
en['SubscribeMaint'] = SubscribeMaintEn
th['SubscribeMaint'] = SubscribeMaintTh
en['BranchGSBMaint'] = BranchGSBMaintEn
th['BranchGSBMaint'] = BranchGSBMaintTh
en['BranchTIPMaint'] = BranchTIPMaintEn
th['BranchTIPMaint'] = BranchTIPMaintTh
en['BranchBCPMaint'] = BranchBCPMaintEn
th['BranchBCPMaint'] = BranchBCPMaintTh
en['Register'] = RegisterEn
th['Register'] = RegisterTh
en['RegisterMaint'] = RegisterMaintEn
th['RegisterMaint'] = RegisterMaintTh
en['PromotionsMaint'] = PromotionsMaintEn
th['PromotionsMaint'] = PromotionsMaintTh
en['workWithUsMaint'] = workWithUsMaintEn
th['workWithUsMaint'] = workWithUsMaintTh
en['ProductMaint'] = ProductMaintEn
th['ProductMaint'] = ProductMaintTh
en['DocumentHeaderMaint'] = DocumentHeaderMaintEn
th['DocumentHeaderMaint'] = DocumentHeaderMaintTh
en['DocumentDetailMaint'] = DocumentDetailMaintEn
th['DocumentDetailMaint'] = DocumentDetailMaintTh
en['UploadMaint'] = UploadMaintEn
th['UploadMaint'] = UploadMaintTh
en['GuaranteeMaint'] = GuaranteeMaintEn
th['GuaranteeMaint'] = GuaranteeMaintTh
en['RejectMaint'] = RejectMaintEn
th['RejectMaint'] = RejectMaintTh
en['UserInformationMaint'] = UserInformationMaintEn
th['UserInformationMaint'] = UserInformationMaintTh
en['ReceiveChannelMaint'] = ReceiveChannelMaintEn
th['ReceiveChannelMaint'] = ReceiveChannelMaintTh
en['ConvenientTimeMaint'] = ConvenientTimeMaintEn
th['ConvenientTimeMaint'] = ConvenientTimeMaintTh
en['CreditLimitMaint'] = CreditLimitMaintEn
th['CreditLimitMaint'] = CreditLimitMaintTh

export default {
  th,
  en,
  jp,
  kr,
  zhHans,
  zhHant
}
