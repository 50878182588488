export default {
  newProductDetail: 'รายละเอียดผลิตภัณฑ์',
  newProductDetailLanguage: 'รายละเอียดผลิตภัณฑ์ภาษา',
  productMaintTitle: 'จัดการผลิตภัณฑ์',
  productDetailMaintTitle: 'จัดการรายละเอียดผลิตภัณฑ์',
  productName: 'ชื่อผลิตภัณฑ์',
  productDetailName: 'ชื่อรายละเอียดผลิตภัณฑ์',
  productDetailType: 'ประเภทรายละเอียดผลิตภัณฑ์',
  productDetailLanguage: 'รายละเอียดผลิตภัณฑ์ภาษา',
  buttonNew: 'เพิ่มรายละเอียด',
  buttonNewLanguage: 'เพิ่มภาษารายละเอียดผลิตภัณฑ์',
  buttonDeleteLanguage: 'ลบภาษารายละเอียดผลิตภัณฑ์',
  buttonDelete: 'ลบรายละเอียด',
  buttonExpand: 'ขยาย',
  buttonCollapse: 'ย่อ',
  buttonFind: 'ค้นหา',
  text: 'ข้อความ',
  image: 'รูปภาพ',
  confirmDelete: 'คุณต้องการลบรายละเอียดผลิตภัณฑ์หรือไม่ ?',
  messageDeleteProductLanguageNotSelected: 'Please select Product Language to delete !',
  AddPicture: 'เพิ่มรูปภาพ',
  buttonNewProduct: 'เพิ่มผลิตภัณฑ์',
  buttonDeleteProduct: 'ลบผลิตภัณฑ์'
}
