<template>
  <div>
       <section>
        <v-row justify="center">
          <v-card class="mx-auto elevation-20">
            <v-img
              :src="require('@/assets/MeeTeeMeeNgern/banner_login.jpg')"
            ></v-img>
            <v-divider></v-divider>
            <v-card-title>
              <span class="blue--text">{{$t('login.configNewPassword')}}</span>
            </v-card-title>
            <v-card-text>
              <v-container fluid
                style="min-height: 0;"
                grid-list-xs>
                <v-form v-model="valid" @submit.prevent="login">
                  <v-row row>
                    <!-- "E-mail/เบอร์โทร/ชื่อผู้ใช้" -->
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" mt-2>
                      <v-text-field prepend-icon="person" outlined single-line
                      name="username"
                      :label="$t('login.userName')"
                      v-model="computeDecode"
                      id="username"
                      ref="refUserName"
                      required
                      readonly></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" mt-2>
                      <v-text-field prepend-icon="lock" outlined single-line
                        name="password"
                        v-model="password"
                        :label="$t('login.newPassword')"
                        id="password"
                        required
                        :append-icon="e3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="e3 ? 'password' : 'text'"
                        @click:append="e3 = !e3"
                        :rules="[() => password.length > 0 || 'Enter your New Password']"
                        >
                        <!-- :hint="$t('login.DescriptionPassword')" -->
                        </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" mt-2>
                      <!-- <v-btn block  color="primary" type="submit">{{$t('login.login')}}</v-btn> -->
                      <v-btn v-if="showchangePassword" block color="#E90587" @click="changePassword()">{{$t('login.forgotPasswordNext')}}</v-btn>
                      <v-btn v-if="!showchangePassword" block color="#E90587" type="submit">{{$t('login.login')}}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" mt-2>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-row>
       </section>
      <!-- <v-layout class="justify-center">
        <span class="justify-center text-xs-center"><small>Copyright &copy; 2020 All Rights Reserved by <br> Innotech Laboratory Services Co.,Ltd.</small></span>
      </v-layout> -->
  </div>
</template>

<style scoped>
  .login {
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 10px;
  }
  .rounded-card{
    border-radius:50px;
  }
  #styled-input {
    height: 22px;
  }
  .styled-input label[for] {
    height: 22px;
  }
  /* .v-text-field input {
    font-size: 1.2em;
  } */
  .v-text-field input {
    font-size: 1.4em;
  }
  /* .v-text-field label {
    font-size: 1.4em;
  } */
  .v-btn label {
    font-size: 1.4em;
  }
</style>

<script>
  import {AUTH_REQUEST} from 'actions/auth'
  import languagePicker from '../navigation/languagePicker.vue'
  import axios from 'axios'
  export default {
    name: 'login',
    components: {
      languagePicker,
    },
    props: {
      prop1: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        stringFormat: require('string-format'),
        /* username: decodeURIComponent(this.prop1), */
        /* username: this.prop1, */
        username: this.computeDecode,
        /* this.computeDecode, */
        /* this.prop1, */
        password: '',
        line_user_id: '',
        e3: true,
        first: '',
        valid: '',
        dialogRegister: false,
        sexCode: '',
        menu2: false,
        date: null,
        dateFormatted: null,
        userNameForm: '',
        anonymousName: '',
        firstName: '',
        lastName: '',
        mobileNo: '',
        eMail: '',
        anonymousFlag: false,
        dialogCondition: false,
        agreeConditionFlag: false,
        firstNameShow: true,
        lastNameShow: true,
        anonymousNameShow: false,
        dialogQuestionnaire: false,
        questionnaire1: '',
        questionnaire2: '',
        questionnaire3: '',
        questionnaire4: '',
        questionnaire5: '',
        questionnaire6: '',
        questionnaire7: '',
        yes: 'ใช่',
        no: 'ไม่ใช่',
        agreeConditionDisable: true,
        /* reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, */
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        regPassword: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^0-9a-zA-Z]).{8,26}$/,
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
        /* passwordRules: [
          value => !!value || 'Please enter a password',
          (value) => !!value || 'Please type password.',
          (value) => (value && /\d/.test(value)) || 'At least one digit',
          (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
          (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
          (value) => (value && value.length < 6 ) || 'minimum 6 characters',
          ] */
        dialogForgotPassword: false,
        usernameForgot: '',
        showUsernameForgot: true,
        showUsernameForgotCard: false,
        dataUser: {},
        showchangePassword: true,
      }
    },
    computed: {
      computedDateFormatted () {
        if (this.date) {
          console.log('A')
          return this.formatDate(this.date)
        } else {
          console.log('B')
          return this.date
        }
      },
      computeDecode () {
        console.log(this.prop1, 'this.prop1 computeDecode1')
        console.log(atob(this.prop1), 'this.prop1 computeDecode2')
        return atob(this.prop1)
      },
      computedGetDate () {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000 // offset in milliseconds
        let tzDate = new Date(Date.now() - tzoffset)
        console.log(tzDate, 'computed: Name ==> computedGetDate')
        return tzDate.toISOString().slice(0, -1)
      },
      sexCodeList () {
        return [
          {code: 'M', name: this.$t('login.male')},
          {code: 'F', name: this.$t('login.female')}
        ]
      },
    },
    created: function () {
      console.log(decodeURIComponent(this.prop1), 'prop1')
      console.log(this.prop1, 'prop1')
        // this.init()
    },
    watch: {
      dialogRegister (val) {
        val || this.closeDialog()
      },
      menu2 (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    methods: {
      login: function () {
        const { username, password } = this
        this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
          if (this.line_user_id) {
            axios.post(process.env.API + '/user/updateFromLineUserID' + process.env.DATABASE_TYPE, {
              user_id: username,
              line_user_id: this.line_user_id
            })
              .then(response => {
                if (response.data.isSuccess) {
                  this.$liff.openWindow({
                    url: process.env.VUE_APP_HTTP_LIFF + process.env.VUE_APP_LIFF_ID_CLINIC_WEB
                  })
                } else {
                  this.$swal({ type: 'error', title: response.data.reasonText })
                }
              })
              .catch(e => {
                this.$swal({ type: 'error', title: e.message })
              })
          } else {
            this.$router.push('/')
          }
        }).catch((error) => {
          // this.$swal({type: 'error', title: typeof (error.message) !== 'undefined' ? error.message : error})
          this.$swal({type: 'error', title: error.message})
        })
      },
      closeDialog () {
        this.dialogRegister = false
        this.resetData()
      },
      loginCheck () {
        console.log('111111111111111')
        if (this.$liff.isLoggedIn()) {
          this.isClient = this.$liff.isInClient()
          this.$liff.getProfile()
            .then(profile => {
              this.line_user_id = profile.userId
              this.eMail = this.$liff.getDecodedIDToken().email
            })
            .catch((err) => {
              this.$swal({type: 'error', title: err.message})
            })
        } else {
          // this.$swal({type: 'error', title: 'not login'})
        }
      },
      openRegister () {
        this.dialogRegister = true
        this.$nextTick(this.$refs.userNameForm.focus)
      },
      // date picker
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      formatDateTime (date) {
        if (!date) return null
        const [year, month, dayTime] = date.split(['-'])
        const [day, Time] = dayTime.split('T')
        var TimeString = Time.substr(0, Time.lastIndexOf('.'))
        return `${day}/${month}/${year} ${TimeString}`
        // const [year, month, day, times] = date.split(['-', 'T'])
        // return `${day}/${month}/${year} ${times}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      resetData () {
        this.userNameForm = ''
        this.sexCode = ''
        this.menu2 = false
        this.date = null
        this.dateFormatted = null
        this.userNameForm = ''
        this.anonymousName = ''
        this.firstName = ''
        this.lastName = ''
        this.mobileNo = ''
        this.eMail = ''
        this.anonymousFlag = false
        this.agreeConditionFlag = false
        this.questionnaire1 = ''
        this.questionnaire2 = ''
        this.questionnaire3 = ''
        this.questionnaire4 = ''
        this.questionnaire5 = ''
        this.questionnaire6 = ''
        this.questionnaire7 = ''
      },
      async save () {
        if (this.validateReg() === true) {
          const [localyear, localmonth, localday] = this.date.split('-')
          var localyear2 = parseInt(localyear)
          /* var localyear2 = parseInt(localyear) + 543 */
          var newPassword = localday + localmonth + localyear2
          var localfirstName = this.firstName
          var locallastName = this.lastName
          if (this.anonymousFlag) {
            localfirstName = this.anonymousName
            locallastName = '-'
          }
          await this.addPatientWebOrder(this.userNameForm,
          this.anonymousName,
          localfirstName,
          locallastName,
          this.sexCode,
          this.mobileNo,
          this.eMail,
          this.date,
          newPassword,
          this.agreeConditionFlag
          )
          this.close()
          this.closeDialog()
        }
      },
      validateReg () {
        var messageCustom = ''
        if (!this.password) {
          messageCustom = messageCustom + this.$t('login.messagePassword') + '<br />'
        }
        if (this.password) {
          if (!this.regPassword.test(this.password)) {
            messageCustom = this.$t('login.invalidPassword') + '<br />'
          }
        }
        if (messageCustom) {
          this.$swal({type: 'warning', html: messageCustom})
          return false
        } else {
          return true
        }
      },
      async addPatientWebOrder (userName, anonymousName, firstName, lastName, sexCode,
      mobileNo, eMail, birthDate, newPassword, agreeConditionFlag) {
        await axios.post(process.env.API + '/web_order/addPatientWebOrder', {
          user_name: userName,
          anonymous_name: anonymousName,
          first_name: firstName,
          last_name: lastName,
          sex_code: sexCode,
          mobile_no: mobileNo,
          eMail: eMail,
          birth_date: birthDate,
          new_password: newPassword,
          agree_condition_flag: agreeConditionFlag,
          questionnaire1: this.questionnaire1,
          questionnaire2: this.questionnaire2,
          questionnaire3: this.questionnaire3,
          questionnaire4: this.questionnaire4,
          questionnaire5: this.questionnaire5,
          line_user_id: this.line_user_id,
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.$swal({
                type: 'success',
                title: this.$t('login.RegisterComplete'),
                text: this.stringFormat(this.$t('login.ShowPassword'), newPassword),
                showConfirmButton: true})
              this.dialogRegister = false
              this.username = this.userNameForm
              this.password = newPassword
              this.login()
              this.resetData()
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      AgreeCondition () {
        this.dialogCondition = false
        this.agreeConditionFlag = true
        this.agreeConditionDisable = false
      },
      checkboxChnage (newValue) {
        console.log(newValue)
        if (newValue) {
          this.firstNameShow = false
          this.lastNameShow = false
          this.anonymousNameShow = true
        } else {
          this.firstNameShow = true
          this.lastNameShow = true
          this.anonymousNameShow = false
        }
      },
      openCondition () {
        this.dialogCondition = true
        this.agreeConditionDisable = false
      },
      init () {
        console.log('event init')
        this.$nextTick(this.$refs.refUserName.focus)
        /* this.$refs.refUserName.focus() */
      },
      openDialogForgotPassword () {
        this.dialogForgotPassword = true
        this.showUsernameForgot = true
        this.usernameForgot = ''
        this.$nextTick(this.$refs.usernameForgot.focus)
        this.showUsernameForgotCard = false
      },
      closeDialogForgotPassword () {
        this.dialogForgotPassword = false
        this.showUsernameForgot = true
        this.usernameForgot = ''
        this.showUsernameForgotCard = false
      },
      sendMail () {
        axios.post(process.env.API + '/user/sendMailForgotPassword', {
          user_id: this.usernameForgot,
          email: this.dataUser.email,
          patientName: this.dataUser.patientname
        })
          .then(response => {
            if (response.data.isSuccess) {
              console.log(response.data.data, 'response.data.data')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      /* changePassword () {
        this.showchangePassword = false
      }, */
      async changePassword () {
        if (this.validateReg() === true) {
          await axios.post(process.env.VUE_APP_API + '/user/updateForgetPassowrd', {
            user_id: this.computeDecode,
            password: this.password
          })
            .then(response => {
              if (response.data.isSuccess) {
                /* this.$swal({
                  type: 'success',
                  title: this.$t('login.configNewPasswordSuccess'),
                  text: this.stringFormat(this.$t('login.ShowNewPassword'), this.password),
                  showConfirmButton: true}) */
                this.username = this.computeDecode
                this.$swal({
                  title: this.$t('login.configNewPasswordSuccess'),
                  text: this.stringFormat(this.$t('login.ShowNewPassword'), this.password),
                  type: 'success',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: this.$t('yes'),
                  cancelButtonText: this.$t('no')
                }).then((result) => {
                  if (result) {
                    this.loginAfterChangePassword()
                    this.showchangePassword = false
                  } else {
                    this.showchangePassword = true
                  }
                })
              } else {
                if (response.data.reasonText === 'Error1') {
                  this.$swal({type: 'warning', title: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเก่า'})
                } else {
                  this.$swal({type: 'error', title: response.data.reasonText})
                }
              }
            })
            .catch(e => {
              this.$swal({type: 'error', title: e.message})
            })
        }
      },
      loginAfterChangePassword () {
        console.log(this.username, 'this.username')
        console.log(this.password, 'this.password')
        this.login()
      },
    },
  }
</script>
