export default {
  BranchBcpTitle: 'Branch BCP',
  buttonNewBranchBcp: 'New Branch BCP',
  buttonNewBranchBcpLanguage: 'New Branch BCP Language',
  buttonDeleteBranchBcp: 'Delete Branch BCP',
  buttonDeleteBranchBcpLanguage: 'Delete Branch BCP Language',
  newBranchBcp: 'New Branch BCP',
  editBranchBcp: 'Edit Branch BCP',
  newBranchBcpLanguage: 'New Branch BCP Language',
  editBranchBcpLanguage: 'Edit Branch BCP Language',
  name: 'Branch BCP Name',
  confirmDelete: 'Are you sure you want to delete Branch BCP {}?',
  confirmBranchBcpLanguageDelete: 'Are you sure you want to delete Branch BCP Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Branch BCP?',
  confirmBranchBcpLanguageDeleteSelected: 'Are you sure you want to delete selected Branch BCP Language?',
  progressMessageDelete: 'Deleting Branch BCP {}',
  progressMessageBranchBcpLanguageDelete: 'Deleting Branch BCP Language {}',
  messageDeleteNotSelected: 'Please select Branch BCP to delete',
  messageDeleteBranchBcpLanguageNotSelected: 'Please select Branch BCP Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  BranchBcpDetail: 'Branch BCP Detail',
  copyHeader: 'Copy From Header',
  street1: 'Address',
  tel: 'Tel.',
}
