export default {
  BranchGsbTitle: 'จัดการสาขาธนาคารออมสิน',
  buttonNewBranchGsb: 'เพิ่มสาขาธนาคารออมสิน',
  buttonNewBranchGsbLanguage: 'เพิ่มภาษาสาขาธนาคารออมสิน',
  buttonDeleteBranchGsb: 'ลบสาขาธนาคารออมสิน',
  buttonDeleteBranchGsbLanguage: 'ลบภาษาสาขาธนาคารออมสิน',
  newBranchGsb: 'เพิ่มสาขาธนาคารออมสิน',
  editBranchGsb: 'แก้ไขสาขาธนาคารออมสิน',
  newBranchGsbLanguage: 'เพิ่มภาษาสาขาธนาคารออมสิน',
  editBranchGsbLanguage: 'แก้ไขภาษาสาขาธนาคารออมสิน',
  name: 'ชื่อสาขาธนาคารออมสิน',
  confirmDelete: 'คุณต้องการที่จะลบสาขาธนาคารออมสิน {}?',
  confirmBranchGsbLanguageDelete: 'คุณต้องการที่จะลบสาขาธนาคารออมสินภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบสาขาธนาคารออมสินที่เลือก?',
  confirmBranchGsbLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาสาขาธนาคารออมสินที่เลือก?',
  progressMessageDelete: 'กำลังลบสาขาธนาคารออมสิน {}',
  progressMessageBranchGsbLanguageDelete: 'กำลังลบภาษาสาขาธนาคารออมสิน {}',
  messageDeleteNotSelected: 'กรุณาเลือกสาขาธนาคารออมสินที่จะลบ',
  messageDeleteBranchGsbLanguageNotSelected: 'กรุณาเลือกภาษาสาขาธนาคารออมสินที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  BranchGsbDetail: 'รายละเอียดสาขาธนาคารออมสิน',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  street1: 'ที่อยู่',
  tel: 'เบอร์โทรศัพท์',
}
