export default {
  WorkWithUsTitle: 'จัดการข้อมูลสมัครงาน',
  buttonNewWorkWithUs: 'เพิ่มข้อมูลสมัครงาน',
  buttonNewWorkWithUsLanguage: 'เพิ่มภาษาข้อมูลสมัครงาน',
  buttonDeleteWorkWithUs: 'ลบข้อมูลสมัครงาน',
  buttonDeleteWorkWithUsLanguage: 'ลบภาษาข้อมูลสมัครงาน',
  newWorkWithUs: 'เพิ่มข้อมูลสมัครงาน',
  editWorkWithUs: 'แก้ไขข้อมูลสมัครงาน',
  newWorkWithUsLanguage: 'เพิ่มภาษาข้อมูลสมัครงาน',
  editWorkWithUsLanguage: 'แก้ไขภาษาข้อมูลสมัครงาน',
  name: 'ชื่อข้อมูลสมัครงาน',
  confirmDelete: 'คุณต้องการที่จะลบข้อมูลสมัครงาน {}?',
  confirmWorkWithUsLanguageDelete: 'คุณต้องการที่จะลบข้อมูลสมัครงานภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบข้อมูลสมัครงานที่เลือก?',
  confirmWorkWithUsLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาข้อมูลสมัครงานที่เลือก?',
  progressMessageDelete: 'กำลังลบข้อมูลสมัครงาน {}',
  progressMessageWorkWithUsLanguageDelete: 'กำลังลบภาษาข้อมูลสมัครงาน {}',
  messageDeleteNotSelected: 'กรุณาเลือกข้อมูลสมัครงานที่จะลบ',
  messageDeleteWorkWithUsLanguageNotSelected: 'กรุณาเลือกภาษาข้อมูลสมัครงานที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  WorkWithUsDetail: 'รายละเอียดข้อมูลสมัครงาน',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  description1: 'รายละเอียด',
  tel: 'เบอร์โทรศัพท์',
  messagePleaseEnterWorkWithUsName: 'กรุณากรอกตำแหน่ง',
  messagePleaseEnterWorkWithUsDetail: 'กรุณากรอกรายละเอียดตำแหน่ง',
  JobApplicationTitle: 'จัดการข้อมูลสมัครงาน',
}
