export default {
  viewReport: '보고서',
  viewHistory: '역사',
  registerNo: 'Register No',
  revNo: 'Rev No',
  hospitalLabNo: 'Hospital Lab No',
  pathoNo: 'Patho No',
  prefix: '접두사',
  name: '환자 이름',
  hn: 'HN',
  physicianName: 'Physician Name',
  customer: '고객',
  receivedDate: '수신 날짜',
  dateReported: '보고 날짜',
  dueDate: 'Due Date',
  tat: 'Turn Around Time',
  labelDateFrom: 'Receive Date From',
  labelDateTo: 'Receive Date To',
  labelBtnFind: '찾기',
  labelPathoPendingList: 'Pathologist Pending Report',
  labelPathoCompleteList: 'Pathologist Complete Report',
  labelPathoHistory: 'Pathology History Report',
}
