<template>
  <!-- <v-app justify="center"> -->
    <div id="home" class="custom-bg">
        <banner />
        <slot />
        <v-divider></v-divider>
        <span v-show="RegisterShow">
          <Register receiveVar="N" ShowMainPage="Y"/>
        </span>
        <!-- <Register receiveVar="N" ShowMainPage="Y"/> -->
        <span v-show="productsShow">
          <Products ShowMainPage="Y"/>
        </span>
        <span v-show="CalShow">
          <Cal ShowImg="N" ShowMainPage="Y" /> 
        </span>
        <span v-show="articlesShow">
          <articles limitShow=3 ShowMainPage="Y" ref="RefArticles"/>
        </span>
        <span v-show="PromotionsShow">
          <Promotions limitShow=3 ShowMainPage="Y" ref="RefPromotions"/>
        </span>
        <!-- <about ShowImg="N" ShowMainPage="Y" />
        <subscribe ShowMainPage="Y"/>
        <FQA /> -->
        <!-- <social /> -->
        <span v-show="FQAShow">
          <FQA />
        </span>
        <span v-show="subscribeShow">
          <subscribe ShowMainPage="Y"/>
        </span>
        <span v-show="WorkWithUsShow">
          <workWithUs ShowImg="N" ShowMainPage="Y"/>
        </span>
      <!-- WorkWithUsShow: false,  -->
    </div>
  <!-- </v-app> -->
</template>

<script>
  import { mapMutations, mapGetters, mapState } from 'vuex'
  import { AUTH_LOGOUT } from 'actions/auth'
import workWithUsMaint from '../translations/locales/en/workWithUsMaint'
  export default {
    name: 'Home',

    components: {
      About: () => import('@/components/home/About'),
      Articles: () => import('@/components/home/Articles'),
      Banner: () => import('@/components/home/Banner'),
      Social: () => import('@/components/home/Social'),
      Subscribe: () => import('@/components/home/Subscribe'),
      Apply: () => import('@/components/home/Apply'),
      Register: () => import('@/components/home/Register'),
      Products: () => import('@/components/home/Products'),
      Promotions: () => import('@/components/home/Promotions'),
      FQA: () => import('@/components/home/FQA'),
      Cal: () => import('@/components/home/Cal'),
      workWithUs: () => import('@/components/home/workWithUs'),
    },
    created () {
      this.checkLogin()
      this.getGlobalRegisterShow()
    },
    data: () => ({
      utils: require('@/utils'),
      RegisterShow: false,
      FQAShow: true,
      articlesShow: false,
      CalShow: false,
      productsShow: false,
      WorkWithUsShow: false,
      subscribeShow: false,
      PromotionsShow: false,
    }),
    methods: {
      async selectLanguage (language) {
        this.$refs.RefArticles.retrieveFrontArticles(language)
      },
      checkLogin () {
        console.log(this.isAuthenticated, 'isAuthenticated')
        if (this.isAuthenticated) {
          this.$router.push('/DashboardMt')
        }
      },
      getGlobalRegisterShow () {
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalRegisterShow', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              if (response.data.data === 'Y' || response.data.data === 'y') {
                this.RegisterShow = true
              } else {
                this.RegisterShow = false
              }
              if (response.data.first_page_show_fqa === 'Y' || response.data.first_page_show_fqa === 'y') {
                this.FQAShow = true
              } else {
                this.FQAShow = false
              }
              if (response.data.first_page_show_articles === 'Y' || response.data.first_page_show_articles === 'y') {
                this.articlesShow = true
              } else {
                this.articlesShow = false
              }
              if (response.data.first_page_show_Cal === 'Y' || response.data.first_page_show_Cal === 'y') {
                this.CalShow = true
              } else {
                this.CalShow = false
              }
              if (response.data.first_page_show_products === 'Y' || response.data.first_page_show_products === 'y') {
                this.productsShow = true
              } else {
                this.productsShow = false
              }
              if (response.data.first_page_show_work_with_us === 'Y' || response.data.first_page_show_work_with_us === 'y') {
                this.WorkWithUsShow = true
              } else {
                this.WorkWithUsShow = false
              }
              if (response.data.first_page_show_subscribe === 'Y' || response.data.first_page_show_subscribe === 'y') {
                this.subscribeShow = true
              } else {
                this.subscribeShow = false
              }
              if (response.data.first_page_show_promotions === 'Y' || response.data.first_page_show_promotions === 'y') {
                this.PromotionsShow = true
              } else {
                this.PromotionsShow = false
              }
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    },
    computed: {
      ...mapState(['drawer']),
      ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded']),
      ...mapState({
        authLoading: state => state.auth.status === 'loading',
        name: state => `${state.user.profile.first_name} ${state.user.profile.last_name}`,
        userID: state => `${state.user.profile.user_id}`,
        picture_base64: state => `${state.user.profile.picture_base64}`,
        token: state => `${state.auth.token}`,
      }),
      tokenExpire: function () {
        return expireTimeTextJwt(this.token)
      },
      isUserPicture () {
        // this.getMenu(this.$i18n.locale)
        return this.picture_base64 !== 'null'
      },
      profileImage () {
        return `data:image/png;base64, ${this.picture_base64}`
      },
    },
  }
</script>
<style scoped>
  .custom-bg {
    background: rgb(242, 245, 247) !important;
    /* background: url('../../assets/bg/bg1.jpg');
    background-size: cover; */
  }
</style>
