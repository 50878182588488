<template>
  <v-app>
    <v-container
      class="pa-0">
      <v-row justify="center" v-show="showLogin">
        <v-card class="mx-auto elevation-20">
          <v-img v-if="(NotTempPassword === false)"
            :src="require('@/assets/MeeTeeMeeNgern/banner_login.jpg')"
          ></v-img>
          <v-divider></v-divider>
          <v-card-text>
            <!-- <v-container> -->
              <v-form v-model="valid" @submit.prevent="login">
                <v-row>
                  <!-- "E-mail/เบอร์โทร/ชื่อผู้ใช้" -->
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" mt-2>
                    <v-text-field prepend-icon="person" outlined single-line
                    name="username"
                    :label="$t('login.userName')"
                    v-model="username"
                    id="username"
                    :rules="[() => username.length > 0 || 'Enter your Username']"
                    ref="refUserName"
                    required></v-text-field>
                  </v-col>
                </v-row>
                <div v-if="(NotTempPassword === false)">
                  <v-row row>
                    <v-col cols="12" xs="12">
                      <v-text-field prepend-icon="lock" outlined single-line
                      name="password"
                      v-model="password"
                      :label="$t('login.password')"
                      id="password"
                      required
                      :append-icon="e3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="e3 ? 'password' : 'text'"
                      @click:append="e3 = !e3"
                      :rules="[() => password.length > 0 || 'Enter your Password']"
                      >
                      <!-- :hint="$t('login.DescriptionPassword')" -->
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row row class="pa-0">
                    <v-col cols="12" xs="12">
                      <v-text-field prepend-icon="lock" outlined single-line
                      name="password"
                      v-model="password"
                      label="รหัสผ่านเก่า"
                      id="password"
                      required
                      :append-icon="e3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="e3 ? 'password' : 'text'"
                      @click:append="e3 = !e3"
                      :rules="[() => password.length > 0 || 'Enter your Password']"
                      >
                      <!-- :hint="$t('login.DescriptionPassword')" -->
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row row class="pa-0">
                    <v-col cols="12" xs="12">
                      <v-text-field prepend-icon="lock" outlined single-line
                      name="passwordNew"
                      v-model="passwordNew"
                      label="รหัสผ่านใหม่"
                      id="passwordNew"
                      required
                      :append-icon="e3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="e3 ? 'password' : 'text'"
                      @click:append="e3 = !e3"
                      :rules="[() => passwordNew.length > 0 || 'Enter your New Password']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row row>
                    <v-col cols="12" xs="12">
                      <v-text-field prepend-icon="lock" outlined single-line
                      name="passwordNewConfirm"
                      v-model="passwordNewConfirm"
                      label="ยืนยันรหัสผ่านใหม่"
                      id="password"
                      required
                      :append-icon="e3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="e3 ? 'password' : 'text'"
                      @click:append="e3 = !e3"
                      :rules="[() => password.length > 0 || 'Enter your Password']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row> -->
                </div>
                <div v-if="(NotTempPassword === false)">
                  <v-row row>
                    <v-col cols='12' xs='12'>
                      <v-btn block outlined color='primary' type='submit'>{{$t('login.login')}}</v-btn>
                    </v-col>
                    <v-col cols='12' xs='12' v-show="!onVerifyFlag">
                      <!-- vue-hcaptcha ของ Google -->
                      <vue-hcaptcha tabindex="0"
                        ref="myCaptcha"
                        sitekey="a7941cc5-1cd1-4658-8613-b0049f9e0c8e"
                        @verify="onVerify"
                        @expired="onExpired"
                        @error="onError"></vue-hcaptcha>
                    </v-col>
                  </v-row>
                  <!-- <div
                    class="h-captcha"
                    data-sitekey="a7941cc5-1cd1-4658-8613-b0049f9e0c8e"
                    data-callback="hacptchaCallback"
                  ></div> -->
                  <!-- import VueRecaptcha from 'vue-recaptcha' -->
                  <v-row row v-show="ShowForgotPassword === 'Y'">
                    <v-col cols="12" xs="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row row v-show="ShowForgotPassword === 'Y'">
                    <v-col cols="6" xs="6" sm="6" md="6">
                      <v-btn text small color="red" class="text-decoration-underline" @click="openDialogForgotPassword()">{{$t('login.forgotPassword')}}</v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row row>
                    <v-col cols="6" xs="6">
                      <v-btn block outlined color="primary" @click="validUserInfo()">บันทึก</v-btn>
                    </v-col>
                    <v-col cols="6" xs="6">
                      <v-btn block outlined color="primary" @click="reset()">ยกเลิก</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            <!-- </v-container> -->
          </v-card-text>
          <!-- <v-divider></v-divider>
          <v-card-text>
            <v-row row>
              <v-col cols="12" xs="12" layout column>
                <div>
                  <span class="text-xs-left body-2">{{$t('login.phone')}}: 0-2244-3000</span>
                </div>
                <div>
                  <span class="text-xs-left body-2">{{$t('login.eMail')}}: odecpr@nmu.ac.th</span>
                </div>
                <div>
                  <span class="text-xs-left body-2">https://www.vajira.ac.th/</span>
                </div>
                <div>
                  <p>
                    <span class="text-xs-left body-2">{{$t('webOrder.ourCompanyaddr1')}}</span>
                    <span class="text-xs-left body-2">
                      {{$t('webOrder.ourCompanyaddr2')}}
                    </span>
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card-text> -->
        </v-card>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogForgotPassword" width="600px" persistent :fullscreen="$vuetify.breakpoint.xs">
      <v-card>
        <v-toolbar dense color="white" card prominent>
          <v-btn icon @click="dialogForgotPassword = false">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title><span class="blue--text">{{$t('login.configNewPassword')}}</span></v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid
            style="min-height: 0;"
            grid-list-xs>
            <v-form v-model="valid" @submit.prevent="login">
              <v-row row>
                <!-- "E-mail/เบอร์โทร/ชื่อผู้ใช้" -->
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" mt-2>
                  <v-text-field prepend-icon="person" outlined single-line
                  name="usernameForgot"
                  :label="$t('login.userName')"
                  v-model="usernameForgot"
                  id="username"
                  :rules="[() => username.length > 0 || 'Enter your Username']"
                  ref="usernameForgot" v-show="showUsernameForgot"
                  required></v-text-field>
                  <!-- this.dataUser.email -->
                  <p>
                    <div class="text-xs-center" v-if="showUsernameForgotCard">
                      <h3>{{$t('login.confirmInfoEmail')}}&nbsp;
                        <span class="red--text">{{this.dataUser.email}}</span>&nbsp;
                        {{$t('login.confirmInfoEmail2')}}</h3>
                    </div>
                  </p>
                </v-col>
              </v-row>
              <v-row row>
                <v-col cols="12" xs="12">
                  <v-btn v-if="!showUsernameForgotCard" block color="primary" @click="forgotPassword()">{{$t('login.forgotPasswordNext')}}</v-btn>
                  <v-btn v-if="showUsernameForgotCard" block color="primary" @click="dialogForgotPassword = false">{{$t('login.confirm')}}</v-btn>
                </v-col>
              </v-row>
              <v-row row>
                <v-col cols="12" xs="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  {{$t('login.contractOur')}}
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <!-- <v-divider></v-divider>
        <v-row class="justify-center">
          <span class="justify-center text-xs-center"><small>Copyright &copy; 2020 All Rights Reserved by <br> คณะแพทยศาสตร์วชิรพยาบาล มหาวิทยาลัยนวมินทราธิราช</small></span>
        </v-row> -->
      </v-card>
    </v-dialog>
    <!-- <v-row
        justify="center"
        no-gutters
      >
        <span class="justify-center text-xs-center"><small>Copyright &copy; 2020 All Rights Reserved by <br> คณะแพทยศาสตร์วชิรพยาบาล มหาวิทยาลัยนวมินทราธิราช</small></span>
      </v-row> -->
  </v-app>
</template>

<style scoped>
  /* .login {
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 10px;
  }
  .rounded-card{
    border-radius:50px;
  } */
</style>

<script>
  import {AUTH_REQUEST} from 'actions/auth'
  import { mapGetters } from 'vuex'
  import languagePicker from '../navigation/languagePicker.vue'
  import SqreenFooter from '@/components/footer/index.vue'
  /* hcaptcha ของ Google */
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
  /* import VueRecaptcha from "vue-recaptcha" */
  /* import VueHcaptcha from '@hcaptcha/vue-hcaptcha' */
  /* import VueRecaptcha from 'vue-recaptcha' */
  export default {
    name: 'login',
    components: {
      languagePicker,
      SqreenFooter,
      /* hcaptcha ของ Google */
      VueHcaptcha,
      /* VueRecaptcha, */
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    data () {
      return {
        username: '',
        password: '',
        passwordNew: '',
        passwordNewConfirm: '',
        NotTempPassword: false,
        e3: true,
        first: '',
        valid: '',
        line_user_id: '',
        stringFormat: require('string-format'),
        sexCode: '',
        menu2: false,
        date: null,
        dateFormatted: null,
        userNameForm: '',
        anonymousName: '',
        firstName: '',
        lastName: '',
        mobileNo: '',
        eMail: '',
        anonymousFlag: false,
        agreeConditionFlag: false,
        firstNameShow: true,
        lastNameShow: true,
        anonymousNameShow: false,
        questionnaire1: '',
        questionnaire2: '',
        questionnaire3: '',
        questionnaire4: '',
        questionnaire5: '',
        questionnaire6: '',
        questionnaire7: '',
        yes: 'ใช่',
        no: 'ไม่ใช่',
        agreeConditionDisable: true,
        /* reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, */
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        regPassword: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^0-9a-zA-Z]).{8,26}$/,
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
        dialogForgotPassword: false,
        usernameForgot: '',
        showUsernameForgot: true,
        showUsernameForgotCard: false,
        dataUser: {},
        btnSaveFlag: false,
        fixed: '',
        breadcrumbsRegister: [
          {
            text: this.$t('login.login'),
            disabled: false,
            href: 'login',
          },
          {
            text: this.$t('login.register'),
            disabled: true,
          },
        ],
        breadcrumbsCondition: [
          {
            text: this.$t('login.register'),
            disabled: true,
          },
          {
            text: this.$t('login.condition'),
            disabled: false,
          },
        ],
        breadcrumbsquestionnaire: [
          {
            text: this.$t('login.register'),
            disabled: true,
          },
          {
            text: this.$t('login.questionnaire'),
            disabled: false,
          },
        ],
        showLogin: true,
        showFistPage: false,
        ShowForgotPassword: '',
        onVerifyFlag: true,
      }
    },
    created: function () {
      /* location.reload() */
      this.getGlobalShowForgotPassword()
    },
    methods: {
      login: function () {
        const { username, password } = this
        if (!username) {
          this.$swal({type: 'warning', title: 'กรุณาใส่รหัสผู้ใช้'})
          return
        }
        if (!password) {
          this.$swal({type: 'warning', title: 'กรุณาใส่รหัสผ่าน'})
          return
        }
        if (!this.onVerifyFlag) {
          this.$swal({type: 'warning', title: 'กรุณา Verilfy Captcha'})
          return
        }
        /* if (this.validateReg(username, password) === true) { */
        console.log(username, 'username')
        console.log(password, 'password')
        this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
          this.NotTempPassword = false
          this.$router.push('/')
        }).catch((error) => {
          // this.$swal({type: 'error', title: typeof (error.message) !== 'undefined' ? error.message : error})
          console.log(error.message, 'error')
          console.log(error, '===================error===========')
          if (error.message === 'temp_password') {
            this.$swal({type: 'warning', title: 'กรุณาตั้งรหัสผ่านใหม่'})
            this.NotTempPassword = true
          } else if (error.message === 'day_password_expiration') {
            this.$swal({type: 'warning', title: 'รหัสผ่านหมดอายุ กรุณาตั้งรหัสผ่านใหม่'})
            this.NotTempPassword = true
          } else if (error.message === 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง') {
            this.$swal({type: 'warning', title: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง'})
            this.NotTempPassword = false
            this.onVerifyFlag = false
          } else if (error.message === 'กรอกชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้องเกินจำนวนครั้งที่กำหนด รอประมาณ 10 นาที จึงเข้าระบบใหม่') {
            this.$swal({type: 'warning', title: 'กรอกชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้องเกินจำนวนครั้งที่กำหนด รอประมาณ 10 นาที จึงเข้าระบบใหม่'})
            this.NotTempPassword = false
            this.onVerifyFlag = true
          } else {
            this.$swal({type: 'error', title: error.message})
            this.NotTempPassword = false
            /* this.onVerifyFlag = false */
          }
          /* day_password_expiration */
        })
        /* } */
      },
      validateReg () {
        var messageCustom = ''
        if (this.password === this.passwordNew) {
          messageCustom = messageCustom + 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเก่า' + '<br />'
        }
        if (this.passwordNew) {
          if (!this.regPassword.test(this.passwordNew)) {
            messageCustom = messageCustom + this.$t('login.invalidPassword') + '<br />'
          }
        }
        if (messageCustom) {
          this.$swal({type: 'warning', html: messageCustom})
          return false
        } else {
          return true
        }
      },
      validUserInfo () {
        if (this.validateReg() === true) {
          if (this.passwordNew.length > 0) {
            this.axios.post(process.env.VUE_APP_API + '/user/validateUserResetPassword' + process.env.VUE_APP_DATABASE_TYPE, {
              user_id: this.username,
              password: this.password
            })
              .then(response => {
                if (response.data.isSuccess) {
                  this.changePassword()
                } else {
                  if (response.data.reasonText === 'Invalid password') {
                    this.$swal({type: 'warning', title: 'รหัสผ่านเก่าไม่ถูกต้อง'})
                  } else {
                    this.$swal({type: 'error', title: response.data.reasonText})
                  }
                }
              })
              .catch(e => {
                this.$swal({type: 'error', title: e.message})
              })
          } else {
            this.changePassword()
          }
        }
      },
      async changePassword () {
        var username = this.username
        await this.axios.post(process.env.VUE_APP_API + '/user/updateForgetPassowrd', {
          user_id: this.username,
          password: this.passwordNew
        })
          .then(response => {
            if (response.data.isSuccess) {
              /* this.$swal({
                type: 'success',
                title: this.$t('login.configNewPasswordSuccess'),
                text: this.stringFormat(this.$t('login.ShowNewPassword'), this.password),
                showConfirmButton: true}) */
              this.$swal({
                title: this.$t('login.configNewPasswordSuccess'),
                text: this.stringFormat(this.$t('login.ShowNewPassword'), this.passwordNew),
                type: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
              }).then((result) => {
                if (result) {
                  this.username = username
                  this.password = this.passwordNew
                  this.loginAfterChangePassword()
                } else {
                  this.reset()
                }
              })
            } else {
              if (response.data.reasonText === 'Error1') {
                this.$swal({type: 'warning', title: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเก่า'})
              } else {
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
        this.reset()
      },
      init () {
        console.log('event init')
        this.$nextTick(this.$refs.refUserName.focus)
        /* this.$refs.refUserName.focus() */
      },
      forgotPassword () {
        console.log(this.usernameForgot, 'Forgot')
        var messageCustom = ''
        if (!this.usernameForgot) {
          messageCustom = messageCustom + this.$t('login.messageUserName') + '<br />'
        }
        if (messageCustom) {
          this.$swal({type: 'error', html: messageCustom})
          return false
        }
        this.retrieveUserInfo()
      },
      openDialogForgotPassword () {
        this.dialogForgotPassword = true
        this.showUsernameForgot = true
        this.usernameForgot = ''
        this.$nextTick(this.$refs.usernameForgot.focus)
        this.showUsernameForgotCard = false
      },
      closeDialogForgotPassword () {
        this.dialogForgotPassword = false
        this.showUsernameForgot = true
        this.usernameForgot = ''
        this.showUsernameForgotCard = false
      },
      sendMail () {
        var base64ParmUser = btoa(this.usernameForgot)
        var fullUrl = window.location.origin + this.$route.path
        console.log(base64ParmUser, 'base64ParmUser')
        console.log(fullUrl, 'fullUrl')
        this.axios.post(process.env.VUE_APP_API + '/user/sendMailForgotPassword', {
          user_id: this.usernameForgot,
          email: this.dataUser.email,
          patientName: this.dataUser.patientname,
          base64Parm: base64ParmUser,
          lang: this.$i18n.locale,
          url: fullUrl,
        })
          .then(response => {
            if (response.data.isSuccess) {
              console.log(response.data.data, 'response.data.data')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      retrieveUserInfo () {
        this.axios.post(process.env.VUE_APP_API + '/user/getUserInfoEmail', {
          user_id: this.usernameForgot
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.dataUser = response.data.data
              console.log(this.dataUser.email, 'email')
              if (!this.dataUser.email) {
                var messageCustom = this.$t('login.messageEmailUser') + '<br />'
                this.$swal({type: 'error', html: messageCustom})
                return false
              } else {
                this.showUsernameForgot = false
                this.showUsernameForgotCard = true
                this.sendMail()
              }
              return true
            } else {
              if (response.data.reasonText === 'Invalid User') {
                this.$swal({type: 'error', title: this.$t('login.messageUserInvalid')})
              } else {
                this.$swal({type: 'error', title: response.data.reasonText})
              }
              return false
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
            return false
          })
      },
      reset () {
        this.NotTempPassword = false
        this.username = ''
        this.password = ''
      },
      loginAfterChangePassword () {
        console.log(this.username, 'this.username')
        console.log(this.password, 'this.password')
        this.login()
      },
      getGlobalShowForgotPassword () {
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalShowForgotPassword', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.ShowForgotPassword = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      onVerify (token, ekey) {
        console.log(token, 'onVerify token')
        console.log(ekey, 'onVerify ekey')
        this.onVerifyFlag = true
        this.$refs.myCaptcha.reset()
      },
      onExpired () {
        console.log('onExpired', 'onExpired')
      },
      onError () {
        console.log('onError', 'onExpired')
      },
      hacptchaCallback (token) {
        console.log('hacptchaCallback', token)
      }
    },
  }
</script>
