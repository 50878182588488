export default {
  DocumentHeaderTitle: 'จัดการกลุ่มเอกสาร',
  buttonnewDocumentHeader: 'เพิ่มกลุ่มเอกสาร',
  buttonnewDocumentHeaderLanguage: 'เพิ่มภาษากลุ่มเอกสาร',
  buttonDeleteDocumentHeader: 'ลบกลุ่มเอกสาร',
  buttonDeleteDocumentHeaderLanguage: 'ลบภาษากลุ่มเอกสาร',
  newDocumentHeader: 'เพิ่มกลุ่มเอกสาร',
  editDocumentHeader: 'แก้ไขกลุ่มเอกสาร',
  newDocumentHeaderLanguage: 'เพิ่มภาษากลุ่มเอกสาร',
  editDocumentHeaderLanguage: 'แก้ไขภาษากลุ่มเอกสาร',
  DocumentHeaderName: 'ชื่อกลุ่มเอกสาร',
  confirmDelete: 'คุณต้องการที่จะลบกลุ่มเอกสาร {}?',
  confirmDocumentHeaderLanguageDelete: 'คุณต้องการที่จะลบกลุ่มเอกสารภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบกลุ่มเอกสารที่เลือก?',
  confirmDocumentHeaderLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษากลุ่มเอกสารที่เลือก?',
  progressMessageDelete: 'กำลังลบกลุ่มเอกสาร {}',
  progressMessageDocumentHeaderLanguageDelete: 'กำลังลบภาษากลุ่มเอกสาร {}',
  messageDeleteNotSelected: 'กรุณาเลือกกลุ่มเอกสารที่จะลบ',
  messageDeleteDocumentHeaderLanguageNotSelected: 'กรุณาเลือกภาษากลุ่มเอกสารที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  DocumentHeaderDetail: 'รายละเอียดกลุ่มเอกสาร',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  messagePleaseEnterArticleName: 'กรุณากรอกชื่อกลุ่มเอกสาร',
  messagePleaseEnterArticleDetail: 'กรุณากรอกรายละเอียดกลุ่มเอกสาร',
  remark: 'หมายเหตุ',
}
