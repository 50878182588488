export default {
  DateReceivedFrom: 'Date Received From',
  DateReceivedTo: 'Date Received To',
  keyword: 'Key Word',
  HeaderSearching: 'Stock Management',
  Receive: 'Receive',
  Issue: 'Issue',
  productGroup: 'Product Group',
  vendor: 'Vendor',
  itemNo: 'Item No',
  itemName: 'Item Name',
  uom: 'UOM',
  stockroom: 'Stockroom',
  location: 'Location',
  docDate: 'Doc Date',
  ReceiveQTY: 'Receive QTY',
  LotNo: 'Lot No.',
  ExpireDate: 'Expire Date',
  ReasonCode: 'Reason',
  PleaseEnterQty: 'Please Enter Qty Moved.',
  PleaseEnterReason: 'Please Enter Reason',
  QtyMovedInvalid: 'Qty Moved Invalid',
  PleaseEnterExpireDate: 'Please Enter Expire Date',
  moveTicket: 'Move Ticket',
  MoveTicketNotFound: 'Move Ticket Not Found',
  ScanMoveTicket: 'Scan Move Ticket',
  ReceiveFromSupplier: 'Receive From Supplier',
  ScanPoNo: 'Scan PO No.',
  PoNotFound: 'PO Not Found',
  DocId: 'Doc Id',
  DocDate: 'Doc Date',
  Facility: 'Facility',
  FacilityName: 'Facility Name',
  Vendor: 'Vendor',
  VendorName: 'Vendor Name',
  InvoiceNo: 'Invoice No',
  Status: 'Status',
  Action: 'Action',
  PoType: 'Po Type',
  resourceNo: 'Resource No',
  resourceName: 'Resource Name',
  remark: 'Remark',
  menu1: 'Products',
  messageCookieLaw: 'We use cookies This website uses cookies to enhance your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from. You can manage your preferences',
  messageCookieLaw2: 'Cookie Policy.',
  messagePolicy: 'นโยบายคุ้มครองข้อมูลส่วนบุคคล',
  CookiePolicyHeader: 'ประกาศความเป็นส่วนตัว',
  CookiePolicyPrivateHeader: 'Cookie Policy',
  Accept: 'Accept',
  NameSurName: 'Name',
  product: 'Product',
  Tel: 'Tel',
  provinces: 'Provinces',
  amphur: 'Amphur',
  tambon: 'Tambon',
  RefCode: 'Ref Code',
  view: 'View',
  readMore: 'Read More',
  aboutUsHeader: 'About Us',
  aboutUs1: 'ธุรกิจที่เกี่ยวข้องกับเรื่องที่ดิน โดยเฉพาะเรื่องขายฝาก มักมีการคิดดอกเบี้ยที่สูงกว่าที่กฎหมายกำหนด และมีปัญหาการยึดที่ทำกินของประชาชน',
  aboutUs2: 'ปัญหาทางเศรษฐกิจที่เกิดขึ้นหลังจาก Covid-19 ประกอบกับภาวะเงินเฟ้อและหนี้ครัวเรือนที่เพิ่มสูงขึ้น ทำให้ผู้ประกอบการหรือประชาชนทั่วไป มีความต้องการเงินทุนที่มีอัตราดอกเบี้ยที่เหมาะสม และรวดเร็ว',
  aboutUs3: 'นโยบายภาครัฐ ที่ต้องการมุ่งเน้นการแก้ไขปัญหาหนี้สินภาคประชาชน โดยสินเชื่อที่ดินและขายฝาก ถือเป็น 1 ในปัญหาดังกล่าว',
  aboutUs4: 'การต่อยอดการเป็นธนาคารเพื่อสังคมของธนาคารออมสิน ที่มุ่งเน้นการส่งเสริมการเข้าถึงแหล่งเงินทุน ที่คิดดอกเบี้ยที่เหมาะสมและเป็นธรรม สามารถสร้างผลกระทบเชิงบวกต่อธุรกิจ หลังจากธุรกิจสินเชื่อจำนำทะเบียนรถก่อนหน้านี้',
  branch: 'Branch',
  Articles: 'Articles',
  promotions: 'Promotions',
  Subscribe: 'Subscribe',
  workWithUs: 'Work with us',
  email: 'e-mail',
  message: 'Message',
  applicationForm: 'Application Form ',
  send: 'Send',
  GoalHeader: 'Goal',
  feature: 'Feature',
  objective: 'Objective',
  fee: 'Fee',
  BranchGSBHeader: 'Branch GSB',
  BranchTIPHeader: 'Branch TIP',
  BranchBCPHeader: 'Branch BCP',
  FindBranch: 'Find Branch',
  FindBranchKey: 'Keyword',
  RegisterButton: 'Register',
  AcceptPolicy: 'Accept Policy',
  RejectPolicy: 'Reject Policy',
  Etc: 'etc.',
  History: 'History',
  employee: 'Employee Only',
  PHONE: 'PHONE',
  FACEBOOK: 'FACEBOOK',
  LINE: 'LINE',
  ADDRESS: 'ADDRESS',
  ADDRESSDetail: '63/2, DHIPAYA INSURANCE PUBLIC COMPANY LIMITED BUILDING, FLOOR 2 RAMA IX, HUAI KHWANG, HUAI KHWANG, BANGKOK 10310',
  EMAIL: 'EMAIL',
  viewMap: 'View Map',
  FAQ: 'FAQ',
  Question1: 'Q: บริษัท มีที่ มีเงิน จำกัด ตั้งอยู่ที่ไหน?',
  Answer1: 'A: 63/2 อาคาร บริษัท ทิพยประกันภัย จำกัด (มหาชน) ชั้น 2 ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพฯ 10310',
  Question2: 'Q: เวลาเปิดและปิดทำการของสำนักงาน?',
  Answer2: 'A: เวลาเปิดและปิดทำการ 8.30 – 17.30 น.',
  Question3: 'Q: ผลิตภัณฑ์มีอะไรบ้างบ้าน?',
  Answer3: 'A: สินเชื่อที่ดิน และขายฝากที่ดิน',
  CalHeader: 'การคำนวณสินเชื่อเบื้องต้น',
  GovernmentPrice: 'ราคาราชการ',
  MarketPrice: 'ราคาตลาด',
  calButton: 'Cal',
  GovernmentCreditLimit: 'วงเงินสูงสุด',
  MarketCreditLimit: 'วงเงินสูงสุด',
  messageGovernment: 'กรุณากรอกราคาราชการ',
  messageMarket: 'กรุณากรอกราคาตลาด',
  DocumentDownload: 'Document Download',
  Home: 'Home',
  InterestedPleaseContact: 'Interested please contact',
  JobPosition: 'Job Position',
  eduHist: 'ประวัติการศึกษา',
  workHist: 'ประวัติการทำงาน',
  JobApplication: 'ตำแหน่งที่ต้องการสมัคร',
  DesiredSalary: 'Desired Salary',
  fileImageName: 'Att. Image',
  fileHist: 'Att. Resume',
  birthDay: 'Birthday',
  firstName: 'First Name',
  lastName: 'Last Name',
  FormJobApplication: 'Form Job Application',
  messageMandatory: 'Please Enter Field *',
  messageFirstName: 'Please Enter First Name.',
  messageLastName: 'Please Enter Last Name.',
  messageAddr: 'Please Enter Address.',
  messageTel: 'Please Enter phone number.',
  messageEmail: 'Please Enter e-mail.',
  messageEduHist: 'Please Enter Education.',
  messageWorkHist: 'Please Enter Work History.',
  messageTelIncorrect: 'Incorrect phone number.',
  messageEmailIncorrect: 'Incorrect e-mail.',
  JobApplicationTitle: 'Job Application Maint',
  downloadFileResume: 'Download Resume',
  policyFlag: 'Accept Policy',
}
