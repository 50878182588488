export default {
  userName: 'รหัสผู้ใช้',
  password: 'รหัสผ่าน',
  newPassword: 'รหัสผ่านใหม่',
  confirmNewPassword: 'ยืนยันรหัสผ่านใหม่',
  login: 'เข้าสู่ระบบ',
  register: 'ลงทะเบียน',
  titleRegister: 'กรุณากรอกข้อมูลจริงเพื่อใช้ในการลงทะเบียน',
  mobileNo: '*หมายเลขโทรศัพท์',
  cancel: 'ยกเลิก',
  save: 'ตกลง',
  userNameForm: '*รหัสผู้ใช้',
  firstName: '*ชื่อ',
  lastName: '*นามสกุล',
  middleName: 'ชื่อกลาง',
  anonymousName: '*นามสมมติ',
  anonymousFlag: '(หากท่านมีความประสงค์ไม่แสดงชื่อ-สกุล จริง ในใบรายงานผล กรุณาระบุนามสมมติ)',
  birthDate: '*วันเกิด',
  gender: '*เพศ',
  eMail: 'Email',
  condition: 'เงื่อนไขและข้อตกลง',
  conditionHeader: 'ข้อกำหนดและเงื่อนไขของผู้ใช้งาน',
  condition1: '1. ความถูกต้องของข้อมูล หรือรายละเอียดส่วนบุคคลของท่าน จะต้องเป็นความจริงและอาจพิสูจน์ถึงตัวตนของท่านได้ ทางบริษัทจะไม่รับผิดชอบต่อความเสียหาย สำหรับข้อมูลที่ไม่เป็นจริง',
  condition2: '2. ข้อมูล ชื่อ หรือ การติดต่อส่วนบุคคลจะต้องเป็นชื่อของเจ้าของผู้สมัครใช้บริการเท่านั้น เราจะไม่รับผิดชอบความเสียหาย ที่เกิดจากการแอบอ้างนำมาซึ่งข้อมูลของบุคคลอื่นเข้าใช้บริการ',
  condition3: '3. ข้อมูลที่ใช้การในการสมัคร จะต้องเป็นความจริง เพื่อไม่ให้เกิดการละเมิดสิทธิ และไม่ผิดกฎหมาย',
  condition4: '4. กรณีที่ท่านต้องการปกปิดข้อมูล ชื่อ – นามสกุล ท่านสามารถทำได้ตามเงื่อนไขที่กำหนดไว้',
  condition5: '5. ผู้ให้บริการจะไม่รับผิดชอบต่อความผิดพลาดในการเข้าใช้งานที่เกิดจากความผิดพลาดของท่าน',
  condition6: '6. ผู้ให้บริการมิได้รับรองว่าข้อมูลและบริการในเว็บไซต์นี้จะเป็นไปตามความต้องการหรือความคาดหมายของผู้ใช้ทุกราย และไม่ได้รับรองว่าการให้บริการจะไม่หยุดชะงัก หรือปราศจากข้อผิดพลาด ข้อบกพร่อง ความล่าช้า และมิได้รับรองว่า ความผิดพลาดใดๆ จะได้รับการแก้ไขในทันทีการใช้บริการขึ้นอยู่กับดุลพินิจของผู้ใช้บริการเองและยอมรับความเสี่ยงสำหรับความเสียหายใดๆที่อาจเกิดขึ้นต่อระบบคอมพิวเตอร์หรือการสูญหายของข้อมูลที่เป็นผลเนื่องมาจากการใช้บริการ โดยท่านไม่อาจเรียกร้องความเสียหายใดๆ ซึ่งเกี่ยวด้วยการให้บริการ',
  condition7: '7. การยอมรับเงื่อนไขในการสมัครเข้าใช้บริการ หมายรวมถึงการที่ท่านอนุญาตให้บริษัทอินโนเทค ลาบอราทอรี่ เซอร์วิส จำกัด ได้เข้าถึงข้อมูล และสำรองข้อมูลการติดต่อ รายชื่อเจ้าของจดหมายอิเลคทรอนิคส์ได้โดยตลอด ทั้งนี้โดยมิต้องสอบถามท่านอีกในแต่ละครั้งที่มีการเข้าถึง หรือสำรองข้อมูลในคราวถัดไป',
  agreeCondition: 'ยอมรับเงื่อนไขและข้อตกลง',
  userNameFormHint: '[A-Z] หรือ [0-9] ไม่เกิน 30 ตัวอักษร',
  messageUserName: '*กรุณาระบุรหัสผู้ใช้',
  messageAnonymous: '*กรุณาระบุนามสมมติ',
  messageFirstName: '*กรุณาระบุชื่อ',
  messageLastName: '*กรุณาระบุนามสกุล',
  messageBirthDate: '*กรุณาระบุวันเกิด',
  messageGender: '*กรุณาระบุเพศ',
  messageMobile: '*กรุณาระบุเบอร์โทรศัพท์',
  messageAgreeCondition: '*กรุณาเลือกยอมรับเงื่อนไขและข้อตกลง',
  DescriptionPassword: 'รหัสผ่านเริ่มต้นคือวันเกิดคุณ (ddmmyyyy ex.01122000)',
  Agree: 'ยอมรับ',
  Disagree: 'ไม่ยอมรับ',
  questionnaire: 'แบบสอบถามคัดกรอง COVID-19',
  confirm: 'ยืนยัน',
  pleaseQuestionnaire: 'กรุณากรอกแบบสอบถามคัดกรอง COVID-19',
  yesQuestionnaire: 'ใช่',
  noQuestionnaire: 'ไม่ใช่',
  Questionnaire1: '1. ท่าน หรือญาติที่ใกล้ชิดมีประวัติอาศัยอยู่หรือเดินทางไปต่างประเทศหรือไม่',
  Questionnaire2: '2. ท่านมีญาติหรือคนใกล้ชิดที่ป่วยเป็นปอดอักเสบ หรือ โรคทางเดินหายใจติดเชื้อหรือไม่',
  Questionnaire3: '3. ท่านมีประวัติใกล้ชิดผู้ป่วยที่เป็นปอดอักเสบ หรือ โรคทางเดินหายใจติดเชื้อ หรือสัมผัสกับผู้ป่วยสงสัยหรือยืนยันโรคติดเชื้อไวรัสโคโรนา 2019หรือไม่',
  Questionnaire4: '4. ท่านเป็นผู้ที่ประกอบอาชีพที่เกี่ยวข้องกับนักท่องเที่ยว สถานที่แออัด หรือติดต่อกับคนจำนวนมาก หรือไม่',
  Questionnaire5: '5. ท่านมีอาการ มีไข้ ไอ หายใจลำบาก หอบเหนื่อย  หรือไม่',
  invalidEmail: 'Email ไม่ถูกต้อง',
  female: 'หญิง',
  male: 'ชาย',
  birthDateFormat: 'DD/MM/YYYY รูปแบบวันที่',
  contractOur: 'หากพบปัญหาหรือข้อสงสัยกรุณาติดต่อผู้ดูแลระบบ บริษัท มีที่ มีเงิน',
  RegisterComplete: 'ลงทะเบียนสำเร็จ',
  ShowPassword: 'รหัสผู้ใช้งานของคุณคือ หมายเลขโทรศัพท์ "{}" รหัสผ่านคุณคือวันเดือนปีเกิด "{}"',
  browse: 'เลือกรูป',
  logoutMessage: 'คุณต้องการออกจากระบบใช้หรือไม่',
  passwordHint: 'ถ้าท่านต้องการเปลี่ยนแปลงข้อมูล กรุณาระบุรหัสผ่านเดิม',
  passwordHintNew: 'ระบุรหัสผ่านใหม่ (กรณีต้องการเปลี่ยนรหัสผ่าน)',
  configNewPassword: 'ตั้งรหัสผ่านใหม่',
  forgotPassword: 'ลืมรหัสผ่าน',
  forgotPasswordNext: 'ถัดไป',
  messageEmailUser: 'ไม่พบอีเมล (ติดต่อ.admin)',
  messagePassword: '*กรุณาระบุรหัสผ่านใหม่',
  confirmInfoEmail: 'ข้อมูลการยืนยันตัวตนจะถูกส่งไปที่',
  confirmInfoEmail2: 'กรุณายืนยัน',
  messageUserInvalid: 'ไม่พบรหัสผู้ใช้',
  close: 'ปิด',
  configNewPasswordSuccess: 'ตั้งรหัสผ่านใหม่สำเร็จ และคุณต้องการเข้าสู่ระบบใช่หรือไม่',
  ShowNewPassword: 'รหัสผ่านใหม่คุณคือ "{}"',
  back: 'ย้อนกลับ',
  phone: 'โทรศัพท์',
  registerDesc: 'ลงทะเบียนใหม่',
  loginDesc: 'เข้าใช้งาน',
  next: 'ถัดไป',
  messageQuestionnaire1: '*กรุณาตอบแบบสอบถามคัดกรอง COVID-19 ข้อที่ 1',
  messageQuestionnaire2: '*กรุณาตอบแบบสอบถามคัดกรอง COVID-19 ข้อที่ 2',
  messageQuestionnaire3: '*กรุณาตอบแบบสอบถามคัดกรอง COVID-19 ข้อที่ 3',
  messageQuestionnaire4: '*กรุณาตอบแบบสอบถามคัดกรอง COVID-19 ข้อที่ 4',
  messageQuestionnaire5: '*กรุณาตอบแบบสอบถามคัดกรอง COVID-19 ข้อที่ 5',
  testCovid: 'ตรวจ  COVID-19',
  invalidPassword: 'รหัสผ่านต้องประกอบไปด้วยอักษรใหญ่+เล็ก+ตัวเลข+อักขระพิเศษ และไม่น้อยกว่า 8 ตัว',
}
