/* eslint-disable promise/param-names */
import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT } from '../actions/auth'
import { USER_REQUEST } from '../actions/user'
import { isValidJwt } from '@/utils'
// import apiCall from 'utils/api'
import axios from 'axios'

const state = { token: localStorage.getItem('user-token') || '', status: '', hasLoadedOnce: false }

const getters = {
  // isAuthenticated: state => !!state.token,
  isAuthenticated (state) {
    return isValidJwt(state.token)
  },
  authStatus: state => state.status,
}

const actions = {
  [AUTH_REQUEST]: ({commit, dispatch}, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      axios({url: process.env.VUE_APP_API + '/user/validateUser' + process.env.VUE_APP_DATABASE_TYPE, data: {user_id: user.username, password: user.password}, method: 'POST'})
      .then(resp => {
        console.log(resp.data)
        if (resp.data.isSuccess) {
          localStorage.setItem('user-token', resp.data.token)
          localStorage.setItem('username', user.username)
          // localStorage.setItem('password', user.password)
          // Here set the header of your ajax library to the token value.
          // example with axios
          axios.defaults.headers.common['Authorization'] = `Bearer-${resp.data.token}`
          commit(AUTH_SUCCESS, resp.data)
          dispatch(USER_REQUEST).then(() => {
            resolve(resp)
          }).catch((error) => {
            // reject(error.err.message)
            reject(error)
          })
        } else {
          commit(AUTH_ERROR, resp.data.reasonText)
          localStorage.removeItem('user-token')
          // reject(resp.data.reasonText)

          var error = new Error(resp.data.reasonText)
          reject(error)
        }
      })
      .catch(err => {
        commit(AUTH_ERROR, err.message)
        localStorage.removeItem('user-token')
        // reject(err.message)
        reject(err)
      })
    })
  },
  [AUTH_LOGOUT]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT)
      localStorage.removeItem('user-token')
      // remove the axios default header
      // delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, userData) => {
    state.status = 'success'
    state.token = userData.token
    state.hasLoadedOnce = true
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
