// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetAlert from 'vue-sweetalert'
import 'vue-tree-halower/dist/halower-tree.min.css'
import {VTree, VSelectTree} from 'vue-tree-halower'
import FlagIcon from 'vue-flag-icon'
import VueBarcode from 'vue-barcode'
import VueQrcode from 'vue-qrcode'
import * as VueGoogleMaps from 'vue2-google-maps'
// import * as socketio from 'socket.io-client'
// import VueSocketIO from 'vue-socket.io'
import VueDragDrop from 'vue-drag-drop'
import Vue2Editor from 'vue2-editor'
import VueHtmlToPaper from 'vue-html-to-paper'
import VuetifyMoney from 'vuetify-money'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import '@mdi/font/css/materialdesignicons.min.css'
import VueCarousel from 'vue-carousel'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
/* import VueHcaptcha from 'vue-hcaptcha-master' */
/* import VueFbCustomerChat from 'vue-fb-customer-chat' */
/* Golf 2023-11-30 */
import VueQRCodeComponent from 'vue-qrcode-component'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueSweetAlert)
Vue.use(VTree)
Vue.use(VSelectTree)
Vue.use(FlagIcon)
Vue.use(VueHcaptcha)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBkRcOvhlhA4jbjEdIg1YD8deB-_ROYwpM',
    libraries: 'places',
  },
  installComponents: true
})

Vue.use(VueDragDrop)
Vue.use(Vue2Editor)
Vue.use(VuetifyMoney)
Vue.use(VueApexCharts)
Vue.use(moment)
Vue.use(VueCarousel)
/* Vue.use(VueHcaptcha) */
Vue.filter('formatDate', function (value, format) {
  if (value) {
    return moment(String(value)).format(format)
  }
})

Vue.component('barcode', VueBarcode)
Vue.component('qrcode', VueQrcode)
Vue.component('apexchart', VueApexCharts)
/* Golf 2023-11-30 */
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueHtmlToPaper, {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
})
/* 107002542133790 */
/* Vue.use(VueFbCustomerChat, {
  page_id: '107822952073520',
  theme_color: '#0202b8',
  locale: 'en_EN',
}) */

new Vue({
  router,
  store,
  vuetify,
  i18n,
  /* render: h => h(App), */
  /* GOlf 2022-12-17 close browser clear localStorage */
  beforeMount () {
    window.addEventListener('load', this.onLoad)
    window.addEventListener('beforeunload', this.onUnload)
  },
  beforeDestroy  () {
    window.removeEventListener('load', this.onLoad)
    window.removeEventListener('beforeunload', this.onUnload)
  },
  methods: {
    onLoad (event) {
      window.localStorage.setItem('isMySessionActive', true)
    },
    onUnload (event) {
      window.localStorage.setItem('isMySessionActive', false)
    }
  },
  render: (h) => h(App)
  /* GOlf 2022-12-17 close browser clear localStorage */
}).$mount('#app')
