export default {
  applicationFormPerson: 'สมัครสินเชื่อ - บุคคลธรรมดา',
  applicationFormCompany: 'สมัครสินเชื่อ - นิติบุคคล',
  product: 'ผลิตภัณฑ์',
  firstName: 'ชื่อ',
  lastName: 'นามสกุล',
  Tel: 'เบอร์โทรศัพท์',
  provinces: 'จังหวัด',
  amphur: 'อำเภอ',
  tambon: 'ตำบล',
  EMAIL: 'อีเมล',
  LINE: 'ไลน์',
  RefCode: 'รหัสอ้างอิง',
  AcceptPolicy: 'ยอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคล',
  RejectPolicy: 'ปฎิเสธนโยบายคุ้มครองข้อมูลส่วนบุคคล',
  Policy: 'ข้าพเจ้าให้ความยินยอมกับบริษัทฯ ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าในการติดต่อเพื่อเสนอผลิตภัณฑ์และบริการของบริษัทฯและรับรองว่าได้อ่านและรับทราบประกาศความเป็นส่วนตัวแล้ว',
  SEND: 'สมัคร',
  messageProduct: 'กรุณาเลือกผลิตภัณฑ์',
  messageName: 'กรุณาใส่ชื่อนามสกุล',
  messageTel: 'กรุณาใส่เบอร์โทร',
  messageProvince: 'กรุณาเลือกจังหวัด',
  messageDistrict: 'กรุณาเลือกอำเภอ',
  messageSubDistrict: 'กรุณาเลือกตำบล',
  messagePolicy: 'ประกาศความเป็นส่วนตัว (คลิกอ่าน)',
  formHeader: 'แบบฟอร์มการสมัคร',
  messageMandatory: 'กรุณากรอกข้อความในช่องที่มีเครื่องหมายดอกจันสีแดง',
  uploadTitle: 'นำเข้าเอกสารการสมัคร',
  OTP: 'One Time Password',
  idCard: 'เลขประจำตัวประชาชน',
  ContactCompany: 'กรณีลูกค้านิติบุคคล กรุณาติดต่อบริษัท มีที่ มีเงิน จำกัด โทร 02-025-6999 หรือธนาคารออมสินสาขาใกล้บ้านท่าน',
  companyName: 'ชื่อ บริษัท',
  companyId: 'เลขนิติบุคคล',
  companyContactFirstName: 'ชื่อ ผู้ติดต่อ',
  companyContactLastName: 'นามสกุล ผู้ติดต่อ',
  companyContactPhone: 'เบอร์โทรศัพท์ ผู้ติดต่อ',
  companyContactEmail: 'อีเมล ผู้ติดต่อ',
  DeedNo: 'เลขที่โฉนด',
  ConvenientTime: 'ช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  prefix: 'คำนำหน้าชือ',
  companyPrefix: 'คำนำหน้าบริษัท',
}
