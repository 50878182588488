export default {
  greeting: 'Welcome to Innotech Clinic',
  chooseLanguage: 'Choose a language',
  flag: 'us',
  profile: 'Profile',
  logout: 'Logout',
  yes: 'Yes',
  no: 'No',
  new: 'New',
  delete: 'Delete',
  save: 'Save',
  query: 'Query',
  find: 'Find',
  cancel: 'Cancel',
  language: 'Language',
  createUser: 'Create User',
  createDate: 'Create Date',
  maintUser: 'Maint User',
  maintDate: 'Maint Date',
  waiting: 'Please waiting',
  search: 'search',
  actions: 'Actions',
  itemsPerPageText: 'Rows per page:',
  itemsPerPageAllText: 'All',
  noData: 'No data available',
  searchNotFound: 'Your search for "{}" found no results.',
  languages: {
    english: 'English',
    thai: 'Thai',
    chineseSimplified: 'Chinese (Simplified)',
    chineseTraditional: 'Chinese (Traditional)',
    japanese: 'Japanese',
    korean: 'Korean',
  },
  waypoint: 'Waypoint',
  logicalWindow: 'Logical Window',
  waitingRetrieve: 'Please waiting for retrieve data',
  waitingSave: 'Please waiting for save data',
  remark: 'Remark',
  Approve: 'Approve',
  warning: 'Warning',
  prePrint: 'Pre Print',
}
