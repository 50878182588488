export default {
  ArticlesTitle: 'Articles',
  buttonnewArticles: 'New Articles',
  buttonnewArticlesLanguage: 'New Articles Language',
  buttonDeleteArticles: 'Delete Articles',
  buttonDeleteArticlesLanguage: 'Delete Articles Language',
  newArticles: 'New Articles',
  editArticles: 'Edit Articles',
  newArticlesLanguage: 'New Articles Language',
  editArticlesLanguage: 'Edit Articles Language',
  articlesName: 'Articles Name',
  confirmDelete: 'Are you sure you want to delete Articles {}?',
  confirmArticlesLanguageDelete: 'Are you sure you want to delete Articles Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Articles?',
  confirmArticlesLanguageDeleteSelected: 'Are you sure you want to delete selected Articles Language?',
  progressMessageDelete: 'Deleting Articles {}',
  progressMessageArticlesLanguageDelete: 'Deleting Articles Language {}',
  messageDeleteNotSelected: 'Please select Articles to delete',
  messageDeleteArticlesLanguageNotSelected: 'Please select Articles Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  articlesDetail: 'Articles Detail',
  copyHeader: 'Copy From Header',
  messagePleaseEnterArticleName: 'Please Enter Article Name',
  messagePleaseEnterArticleDetail: 'Please Enter Article Detail',
}
