export default {
  PromotionsTitle: 'Promotions',
  buttonnewPromotions: 'New Promotions',
  buttonnewPromotionsLanguage: 'New Promotions Language',
  buttonDeletePromotions: 'Delete Promotions',
  buttonDeletePromotionsLanguage: 'Delete Promotions Language',
  newPromotions: 'New Promotions',
  editPromotions: 'Edit Promotions',
  newPromotionsLanguage: 'New Promotions Language',
  editPromotionsLanguage: 'Edit Promotions Language',
  promotionsName: 'Promotions Name',
  confirmDelete: 'Are you sure you want to delete Promotions {}?',
  confirmPromotionsLanguageDelete: 'Are you sure you want to delete Promotions Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Promotions?',
  confirmPromotionsLanguageDeleteSelected: 'Are you sure you want to delete selected Promotions Language?',
  progressMessageDelete: 'Deleting Promotions {}',
  progressMessagePromotionsLanguageDelete: 'Deleting Promotions Language {}',
  messageDeleteNotSelected: 'Please select Promotions to delete',
  messageDeletePromotionsLanguageNotSelected: 'Please select Promotions Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  promotionsDetail: 'Promotions Detail',
  copyHeader: 'Copy From Header',
  messagePleaseEnterPromotionsName: 'Please Enter Promotions Name',
  messagePleaseEnterPromotionsDetail: 'Please Enter Promotions Detail',
}
