import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import BaseBtn from '@/components/base/Btn'
import Card from '@/components/base/Card'
import Subheading from '@/components/base/Subheading'

const requireComponent = require.context(
  '@/components/base', true, /\.vue$/,
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')),
  )

  Vue.component(`Base${componentName}`, componentConfig.default || componentConfig)

  Vue.component(BaseBtn.name, BaseBtn)
  Vue.component(Card.name, Card)
  Vue.component(Subheading.name, Subheading)
})
