export default {
  windowTitle: 'หน้าต่าง',
  buttonNewWindow: 'เพิ่มหน้าต่าง',
  buttonDeleteWindow: 'ลบหน้าต่าง',
  newWindow: 'เพิ่มหน้าต่าง',
  editWindow: 'แก้ไขหน้าต่าง',
  windowName: 'ชื่อหน้าต่าง',
  windowComment: 'อธิบายหน้าต่าง',
  confirmDelete: 'คุณต้องการที่จะลบหน้าจอ {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบหน้าจอที่เลือก?',
  progressMessageDelete: 'กำลังลบหน้าต่าง {}',
  messageDeleteNotSelected: 'กรุณาเลือกหน้าต่างที่จะลบ',
}
