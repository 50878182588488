export default {
  BranchTipTitle: 'จัดการสาขาทิพยประกันภัย',
  buttonNewBranchTip: 'เพิ่มสาขาทิพยประกันภัย',
  buttonNewBranchTipLanguage: 'เพิ่มภาษาสาขาทิพยประกันภัย',
  buttonDeleteBranchTip: 'ลบสาขาทิพยประกันภัย',
  buttonDeleteBranchTipLanguage: 'ลบภาษาสาขาทิพยประกันภัย',
  newBranchTip: 'เพิ่มสาขาทิพยประกันภัย',
  editBranchTip: 'แก้ไขสาขาทิพยประกันภัย',
  newBranchTipLanguage: 'เพิ่มภาษาสาขาทิพยประกันภัย',
  editBranchTipLanguage: 'แก้ไขภาษาสาขาทิพยประกันภัย',
  name: 'ชื่อสาขาทิพยประกันภัย',
  confirmDelete: 'คุณต้องการที่จะลบสาขาทิพยประกันภัย {}?',
  confirmBranchTipLanguageDelete: 'คุณต้องการที่จะลบสาขาทิพยประกันภัยภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบสาขาทิพยประกันภัยที่เลือก?',
  confirmBranchTipLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาสาขาทิพยประกันภัยที่เลือก?',
  progressMessageDelete: 'กำลังลบสาขาทิพยประกันภัย {}',
  progressMessageBranchTipLanguageDelete: 'กำลังลบภาษาสาขาทิพยประกันภัย {}',
  messageDeleteNotSelected: 'กรุณาเลือกสาขาทิพยประกันภัยที่จะลบ',
  messageDeleteBranchTipLanguageNotSelected: 'กรุณาเลือกภาษาสาขาทิพยประกันภัยที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  BranchTipDetail: 'รายละเอียดสาขาทิพยประกันภัย',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  street1: 'ที่อยู่',
  tel: 'เบอร์โทรศัพท์',
}
