export default {
  greeting: 'ยินดีต้อนรับสู่ Innotech Clinic',
  chooseLanguage: 'เลือกภาษา',
  flag: 'th',
  profile: 'ข้อมูลส่วนตัว',
  logout: 'ออกจากระบบ',
  yes: 'ใช่',
  no: 'ไม่ใช่',
  new: 'ใหม่',
  delete: 'ลบ',
  save: 'บันทึก',
  query: 'สอบถาม',
  find: 'ค้นหา',
  cancel: 'ยกเลิก',
  language: 'ภาษา',
  createUser: 'ผู้สร้าง',
  createDate: 'วันที่สร้าง',
  maintUser: 'ผู้ปรับปรุง',
  maintDate: 'วันที่ปรับปรุง',
  waiting: 'กรุณารอสักครู่',
  search: 'ค้นหา',
  actions: 'การกระทำ',
  itemsPerPageText: 'จำนวนแถวต่อหน้า:',
  itemsPerPageAllText: 'ทั้งหมด',
  noData: 'ไม่พบข้อมูุล',
  searchNotFound: 'ไม่พบ "{}" ในผลลัพธ์',
  languages: {
    english: 'อังกฤษ',
    thai: 'ไทย',
    chineseSimplified: 'จีนตัวย่อ',
    chineseTraditional: 'จีนตัวเต็ม',
    japanese: 'ญี่่ปุ่น',
    korean: 'เกาหลี',
  },
  waypoint: 'กล่อง',
  logicalWindow: 'หน้าต่างทำงาน',
  waitingRetrieve: 'กรุณารอสักครู่กำลังทำการดึงข้อมูล',
  waitingSave: 'กรุณารอสักครู่กำลังทำการบันทึกข้อมูล',
  remark: 'หมายเหตุ',
  Approve: 'Approve',
  warning: 'Warning',
  prePrint: 'Pre Print',
}
