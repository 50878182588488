export default {
  BranchGsbTitle: 'Branch GSB',
  buttonNewBranchGsb: 'New Branch GSB',
  buttonNewBranchGsbLanguage: 'New Branch GSB Language',
  buttonDeleteBranchGsb: 'Delete Branch GSB',
  buttonDeleteBranchGsbLanguage: 'Delete Branch GSB Language',
  newBranchGsb: 'New Branch GSB',
  editBranchGsb: 'Edit Branch GSB',
  newBranchGsbLanguage: 'New Branch GSB Language',
  editBranchGsbLanguage: 'Edit Branch GSB Language',
  name: 'Branch GSB Name',
  confirmDelete: 'Are you sure you want to delete Branch GSB {}?',
  confirmBranchGsbLanguageDelete: 'Are you sure you want to delete Branch GSB Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Branch GSB?',
  confirmBranchGsbLanguageDeleteSelected: 'Are you sure you want to delete selected Branch GSB Language?',
  progressMessageDelete: 'Deleting Branch GSB {}',
  progressMessageBranchGsbLanguageDelete: 'Deleting Branch GSB Language {}',
  messageDeleteNotSelected: 'Please select Branch GSB to delete',
  messageDeleteBranchGsbLanguageNotSelected: 'Please select Branch GSB Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  BranchGsbDetail: 'Branch GSB Detail',
  copyHeader: 'Copy From Header',
  street1: 'Address',
  tel: 'Tel.',
}
