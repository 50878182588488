export default {
  UploadTitle: 'จัดการอัพโหลดเอกสารการสมัคร',
  buttonnewUpload: 'เพิ่มเอกสารการสมัคร',
  buttonnewUploadLanguage: 'เพิ่มภาษาเอกสารการสมัคร',
  buttonDeleteUpload: 'ลบเอกสารการสมัคร',
  buttonDeleteUploadLanguage: 'ลบภาษาเอกสารการสมัคร',
  newUpload: 'เพิ่มเอกสารการสมัคร',
  editUpload: 'แก้ไขเอกสารการสมัคร',
  newUploadLanguage: 'เพิ่มภาษาเอกสารการสมัคร',
  editUploadLanguage: 'แก้ไขภาษาเอกสารการสมัคร',
  uploadName: 'ชื่อเอกสารการสมัคร',
  confirmDelete: 'คุณต้องการที่จะลบเอกสารการสมัคร {}?',
  confirmUploadLanguageDelete: 'คุณต้องการที่จะลบเอกสารการสมัครภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบเอกสารการสมัครที่เลือก?',
  confirmUploadLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาเอกสารการสมัครที่เลือก?',
  progressMessageDelete: 'กำลังลบเอกสารการสมัคร {}',
  progressMessageUploadLanguageDelete: 'กำลังลบภาษาเอกสารการสมัคร {}',
  messageDeleteNotSelected: 'กรุณาเลือกเอกสารการสมัครที่จะลบ',
  messageDeleteUploadLanguageNotSelected: 'กรุณาเลือกภาษาเอกสารการสมัครที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  uploadDetail: 'รายละเอียดเอกสารการสมัคร',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
}
