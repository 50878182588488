export default {
  DocumentDetailTitle: 'Document',
  buttonnewDocumentDetail: 'New Document',
  buttonnewDocumentDetailLanguage: 'New Document Language',
  buttonDeleteDocumentDetail: 'Delete Document',
  buttonDeleteDocumentDetailLanguage: 'Delete Document Language',
  newDocumentDetail: 'New Document',
  editDocumentDetail: 'Edit Document',
  newDocumentDetailLanguage: 'New Document Language',
  editDocumentDetailLanguage: 'Edit Document Language',
  DocumentDetailName: 'Document Name',
  confirmDelete: 'Are you sure you want to delete Document {}?',
  confirmDocumentDetailLanguageDelete: 'Are you sure you want to delete Document Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Document ?',
  confirmDocumentDetailLanguageDeleteSelected: 'Are you sure you want to delete selected Document Language?',
  progressMessageDelete: 'Deleting Document {}',
  progressMessageDocumentDetailLanguageDelete: 'Deleting Document Language {}',
  messageDeleteNotSelected: 'Please select Document to delete',
  messageDeleteDocumentDetailLanguageNotSelected: 'Please select Document Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  DocumentDetailDetail: 'Document Detail',
  copyHeader: 'Copy From Header',
  messagePleaseEnterArticleName: 'Please Enter Name',
  messagePleaseEnterArticleDetail: 'Please Enter Remark',
  messagePleaseEnterDocumentHeader: 'Please Enter DocumentHeader',
  ChooseFile: 'Choose File',
  FileName: 'File Name',
  remark: 'Remark',
  Download: 'Download',
  messagePleaseSelectFileUpload: 'Please Select File Upload',
}
