export default {
  greeting: 'Welcome to Innotech Clinic',
  chooseLanguage: '언어를 선택하십시오',
  flag: 'kr',
  profile: '윤곽',
  logout: '로그 아웃',
  yes: '예',
  no: '아니',
  new: 'New',
  delete: 'Delete',
  save: '구하다',
  query: 'Query',
  find: 'Find',
  cancel: '취소',
  language: '언어',
  createUser: 'Create User',
  createDate: 'Create Date',
  maintUser: 'Maint User',
  maintDate: 'Maint Date',
  waiting: 'Please waiting',
  search: '수색',
  actions: '동작',
  itemsPerPageText: 'Rows per page:',
  itemsPerPageAllText: 'All',
  noData: 'No data available',
  searchNotFound: 'Your search for "{}" found no results.',
  languages: {
    english: 'English',
    thai: 'Thai',
    chineseSimplified: 'Chinese (Simplified)',
    chineseTraditional: 'Chinese (Traditional)',
    japanese: 'Japanese',
    korean: 'Korean',
  },
  waypoint: 'Waypoint',
  logicalWindow: 'Logical Window',
}
