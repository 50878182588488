export default {
  DocumentHeaderTitle: 'Document Header',
  buttonnewDocumentHeader: 'New Document Header',
  buttonnewDocumentHeaderLanguage: 'New Document Header Language',
  buttonDeleteDocumentHeader: 'Delete Document Header',
  buttonDeleteDocumentHeaderLanguage: 'Delete Document Header Language',
  newDocumentHeader: 'New Document Header',
  editDocumentHeader: 'Edit Document Header',
  newDocumentHeaderLanguage: 'New Document Header Language',
  editDocumentHeaderLanguage: 'Edit Document Header Language',
  DocumentHeaderName: 'Document Header Name',
  confirmDelete: 'Are you sure you want to delete Document Header {}?',
  confirmDocumentHeaderLanguageDelete: 'Are you sure you want to delete Document Header Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Document Header ?',
  confirmDocumentHeaderLanguageDeleteSelected: 'Are you sure you want to delete selected Document Header Language?',
  progressMessageDelete: 'Deleting Document Header {}',
  progressMessageDocumentHeaderLanguageDelete: 'Deleting Document Header Language {}',
  messageDeleteNotSelected: 'Please select Document Header to delete',
  messageDeleteDocumentHeaderLanguageNotSelected: 'Please select Document Header Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  DocumentHeaderDetail: 'Document Header Detail',
  copyHeader: 'Copy From Header',
  messagePleaseEnterArticleName: 'Please Enter Name',
  messagePleaseEnterArticleDetail: 'Please Enter Remark',
  remark: 'Remark',
}
