export default {
  viewReport: '報告',
  viewHistory: '歷史',
  registerNo: 'Register No',
  revNo: 'Rev No',
  hospitalLabNo: 'Hospital Lab No',
  pathoNo: 'Patho No',
  prefix: '字首',
  name: '患者姓名',
  hn: 'HN',
  physicianName: 'Physician Name',
  customer: '顧客',
  receivedDate: '收到日期',
  dateReported: '報告日期',
  dueDate: 'Due Date',
  tat: 'Turn Around Time',
  labelDateFrom: 'Receive Date From',
  labelDateTo: 'Receive Date To',
  labelBtnFind: '找',
  labelPathoPendingList: 'Pathologist Pending Report',
  labelPathoCompleteList: 'Pathologist Complete Report',
  labelPathoHistory: 'Pathology History Report',
}
