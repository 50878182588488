export default {
  greeting: 'Welcome to Innotech Clinic',
  chooseLanguage: '選擇一種語言',
  flag: 'cn',
  profile: '輪廓',
  logout: '登出',
  yes: '是',
  no: '沒有',
  new: 'New',
  delete: 'Delete',
  save: '保存',
  query: 'Query',
  find: 'Find',
  cancel: '取消',
  language: '語言',
  createUser: 'Create User',
  createDate: 'Create Date',
  maintUser: 'Maint User',
  maintDate: 'Maint Date',
  waiting: 'Please waiting',
  search: '搜索',
  actions: '行動',
  itemsPerPageText: 'Rows per page:',
  itemsPerPageAllText: 'All',
  noData: 'No data available',
  searchNotFound: 'Your search for "{}" found no results.',
  languages: {
    english: 'English',
    thai: 'Thai',
    chineseSimplified: 'Chinese (Simplified)',
    chineseTraditional: 'Chinese (Traditional)',
    japanese: 'Japanese',
    korean: 'Korean',
  },
  waypoint: 'Waypoint',
  logicalWindow: 'Logical Window',
}
