export default {
  DateReceivedFrom: 'Date Received From',
  DateReceivedTo: 'Date Received To',
  keyword: 'Key Word',
  HeaderSearching: 'Stock Management',
  Receive: 'Receive',
  Issue: 'Issue',
  productGroup: 'Product Group',
  vendor: 'Vendor',
  itemNo: 'Item No',
  itemName: 'Item Name',
  uom: 'UOM',
  stockroom: 'Stockroom',
  location: 'Location',
  docDate: 'Doc Date',
  ReceiveQTY: 'Receive QTY',
  LotNo: 'Lot No.',
  ExpireDate: 'Expire Date',
  ReasonCode: 'Reason',
  PleaseEnterQty: 'Please Enter Qty Moved.',
  PleaseEnterReason: 'Please Enter Reason',
  QtyMovedInvalid: 'Qty Moved Invalid',
  PleaseEnterExpireDate: 'Please Enter Expire Date',
  moveTicket: 'Move Ticket',
  MoveTicketNotFound: 'Move Ticket Not Found',
  ScanMoveTicket: 'Scan Move Ticket',
  ReceiveFromSupplier: 'Receive From Supplier',
  ScanPoNo: 'Scan PO No.',
  PoNotFound: 'PO Not Found',
  DocId: 'Doc Id',
  DocDate: 'Doc Date',
  Facility: 'Facility',
  FacilityName: 'Facility Name',
  Vendor: 'Vendor',
  VendorName: 'Vendor Name',
  InvoiceNo: 'Invoice No',
  Status: 'Status',
  Action: 'Action',
  PoType: 'Po Type',
  resourceNo: 'Resource No',
  resourceName: 'Resource Name',
  remark: 'Remark',
}
