export default {
  RejectTitle: 'Reject',
  buttonnewReject: 'New Reject',
  buttonnewRejectLanguage: 'New Reject Language',
  buttonDeleteReject: 'Delete Reject',
  buttonDeleteRejectLanguage: 'Delete Reject Language',
  newReject: 'New Reject',
  editReject: 'Edit Reject',
  newRejectLanguage: 'New Reject Language',
  editRejectLanguage: 'Edit Reject Language',
  rejectName: 'Reject Name',
  confirmDelete: 'Are you sure you want to delete Reject {}?',
  confirmRejectLanguageDelete: 'Are you sure you want to delete Reject Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Reject?',
  confirmRejectLanguageDeleteSelected: 'Are you sure you want to delete selected Reject Language?',
  progressMessageDelete: 'Deleting Reject {}',
  progressMessageRejectLanguageDelete: 'Deleting Reject Language {}',
  messageDeleteNotSelected: 'Please select Reject to delete',
  messagedeleteLanguageNotSelected: 'Please select Reject Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  rejectDetail: 'Reject Detail',
  copyHeader: 'Copy From Header',
}
