export default {
  RejectTitle: 'จัดการเหตุผลการยกเลิก/ปฏิเสธ',
  buttonnewReject: 'เพิ่มเหตุผลการยกเลิก/ปฏิเสธ',
  buttonnewRejectLanguage: 'เพิ่มภาษาเหตุผลการยกเลิก/ปฏิเสธ',
  buttonDeleteReject: 'ลบเหตุผลการยกเลิก/ปฏิเสธ',
  buttonDeleteRejectLanguage: 'ลบภาษาเหตุผลการยกเลิก/ปฏิเสธ',
  newReject: 'เพิ่มเหตุผลการยกเลิก/ปฏิเสธ',
  editReject: 'แก้ไขเหตุผลการยกเลิก/ปฏิเสธ',
  newRejectLanguage: 'เพิ่มภาษาเหตุผลการยกเลิก/ปฏิเสธ',
  editRejectLanguage: 'แก้ไขภาษาเหตุผลการยกเลิก/ปฏิเสธ',
  rejectName: 'ชื่อเหตุผลการยกเลิก/ปฏิเสธ',
  confirmDelete: 'คุณต้องการที่จะลบเหตุผลการยกเลิก/ปฏิเสธ {}?',
  confirmRejectLanguageDelete: 'คุณต้องการที่จะลบเหตุผลการยกเลิก/ปฏิเสธภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบเหตุผลการยกเลิก/ปฏิเสธที่เลือก?',
  confirmRejectLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาเหตุผลการยกเลิก/ปฏิเสธที่เลือก?',
  progressMessageDelete: 'กำลังลบเหตุผลการยกเลิก/ปฏิเสธ {}',
  progressMessageRejectLanguageDelete: 'กำลังลบภาษาเหตุผลการยกเลิก/ปฏิเสธ {}',
  messageDeleteNotSelected: 'กรุณาเลือกเหตุผลการยกเลิก/ปฏิเสธที่จะลบ',
  messagedeleteLanguageNotSelected: 'กรุณาเลือกภาษาเหตุผลการยกเลิก/ปฏิเสธที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  rejectDetail: 'รายละเอียดเหตุผลการยกเลิก/ปฏิเสธ',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
}
