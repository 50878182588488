export default {
  product: 'Product',
  firstName: 'First Name',
  lastName: 'Last Name',
  Tel: 'Phone',
  provinces: 'Province',
  amphur: 'District',
  tambon: 'Sub District',
  EMAIL: 'Email',
  LINE: 'Line',
  RefCode: 'Ref Code',
  AcceptPolicy: 'Accept Policy',
  RejectPolicy: 'Reject Policy',
  Policy: 'ข้าพเจ้าให้ความยินยอมกับบริษัทฯ ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าในการติดต่อเพื่อเสนอผลิตภัณฑ์และบริการของบริษัทฯและรับรองว่าได้อ่านและรับทราบประกาศความเป็นส่วนตัวแล้ว',
  SEND: 'Send',
  Title: 'Edit Register',
  messageProduct: 'Please Enter Product',
  messageName: 'Please Enter Name',
  messageTel: 'Please Enter Phone Number',
  messageProvince: 'Please Enter Province',
  messageDistrict: 'Please Enter District',
  messageSubDistrict: 'Please Enter Sub District',
  messagePolicy: 'Please Enter Policy',
  formHeader: 'Application Form',
  messageMandatory: 'Please Enter Field *',
  uploadTitle: 'Upload Register Files',
  addFile: 'Add File',
  Status: 'Status',
  idCard: 'ID Card',
  prefix: 'Prefix',
  HouseNo: 'House No',
  Moo: 'Moo Tee',
  Trog: 'Trog',
  Soi: 'Soi',
  Road: 'Road',
  birthDay: 'Birthday',
  NewRegister: 'Add New Register',
  Individual: 'Individual',
  Company: 'Company',
  companyName: 'Company Name',
  companyId: 'Company ID',
  contactPersonFirstName: 'Contact Person First Name',
  contactPersonLastName: 'Contact Person Last Name',
  uploadFile: 'Upload',
  downloadFile: 'Download zip file',
  docName: 'ชื่อเอกสาร',
  TelContact: 'เบอร์โทรผู้ติดต่อ',
  remark: 'Remark',
  fileName: 'File Name',
  DeleteFile: 'Delete File',
  DownloadFile: 'Downlaod File',
  PreviewFile: 'View File',
  confirmDeleteSelected: 'Do you want to delete file ?',
  Ok: 'OK',
  Cancel: 'Cancel',
  readIdCard: 'Read From Card',
  findData: 'Find',
  addData: 'Add',
  clearData: 'Clear',
  CreditLimit: 'Credit Limit',
  Guarantee: 'Guarantee',
  GuaranteeProvince: 'Guarantee Province',
  Reject: 'Reject',
  DeedNo: 'Deed No',
  ConvenientTime: 'Convenient Time',
  ReceiveChannel: 'Receive Channel',
  companyPrefix: 'Company Prefix',
  addRemark: 'Add Remark',
  dateRemark: 'Date Remark',
}
