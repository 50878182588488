export default {
  GuaranteeTitle: 'จัดการประเภทหลักประกัน',
  buttonnewGuarantee: 'เพิ่มประเภทหลักประกัน',
  buttonnewGuaranteeLanguage: 'เพิ่มภาษาประเภทหลักประกัน',
  buttonDeleteGuarantee: 'ลบประเภทหลักประกัน',
  buttonDeleteGuaranteeLanguage: 'ลบภาษาประเภทหลักประกัน',
  newGuarantee: 'เพิ่มประเภทหลักประกัน',
  editGuarantee: 'แก้ไขประเภทหลักประกัน',
  newGuaranteeLanguage: 'เพิ่มภาษาประเภทหลักประกัน',
  editGuaranteeLanguage: 'แก้ไขภาษาประเภทหลักประกัน',
  guaranteeName: 'ชื่อประเภทหลักประกัน',
  confirmDelete: 'คุณต้องการที่จะลบประเภทหลักประกัน {}?',
  confirmGuaranteeLanguageDelete: 'คุณต้องการที่จะลบประเภทหลักประกันภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบประเภทหลักประกันที่เลือก?',
  confirmGuaranteeLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาประเภทหลักประกันที่เลือก?',
  progressMessageDelete: 'กำลังลบประเภทหลักประกัน {}',
  progressMessageGuaranteeLanguageDelete: 'กำลังลบภาษาประเภทหลักประกัน {}',
  messageDeleteNotSelected: 'กรุณาเลือกประเภทหลักประกันที่จะลบ',
  messageDeleteLanguageNotSelected: 'กรุณาเลือกภาษาประเภทหลักประกันที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  guaranteeDetail: 'รายละเอียดประเภทหลักประกัน',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
}
