export default {
  ReceiveChannelTitle: 'Receive Channel',
  buttonnewReceiveChannel: 'New Receive Channel',
  buttonnewReceiveChannelLanguage: 'New Receive Channel Language',
  buttonDeleteReceiveChannel: 'Delete Receive Channel',
  buttonDeleteReceiveChannelLanguage: 'Delete Receive Channel Language',
  newReceiveChannel: 'New Receive Channel',
  editReceiveChannel: 'Edit Receive Channel',
  newReceiveChannelLanguage: 'New Receive Channel Language',
  editReceiveChannelLanguage: 'Edit Receive Channel Language',
  receiveChannelName: 'Receive Channel Name',
  confirmDelete: 'Are you sure you want to delete Receive Channel {}?',
  confirmReceiveChannelLanguageDelete: 'Are you sure you want to delete Receive Channel Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Receive Channel?',
  confirmReceiveChannelLanguageDeleteSelected: 'Are you sure you want to delete selected Receive Channel Language?',
  progressMessageDelete: 'Deleting Receive Channel {}',
  progressMessageReceiveChannelLanguageDelete: 'Deleting Receive Channel Language {}',
  messageDeleteNotSelected: 'Please select Receive Channel to delete',
  messagedeleteLanguageNotSelected: 'Please select Receive Channel Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  receiveChannelDetail: 'Receive Channel Detail',
  copyHeader: 'Copy From Header',
}
