export default {
  appointment: 'นัดหมายล่วงหน้า',
  myAppointment: 'ข้อมูลการนัดหมาย',
  report: 'รายงานผลการตรวจวิเคราะห์',
  titleMenuCovid: 'การให้บริการตรวจ COVID-19',
  DescriptonAnalysis: 'คำอธิบายผลการตรวจวิเคราะห์',
  userManual: 'แนะนำการใช้งาน',
  regulation: 'ข้อควรปฏิบัติ',
  generalInfo: 'ข้อมูลทั่วไป',
  appointmentDate: 'วันที่นัด',
  slotNo: 'ลำดับ',
  slotTime: 'เวลานัด',
  slotAppointmentQty: 'จำนวนว่าง',
  appointment2: 'นัด',
  confirmAppointment: 'คุณต้องการที่จะนัดหมาย {}?',
  cancelAppointment: 'คุณต้องการที่จะยกเลิกนัดหมาย {}?',
  labelAppointmentID: 'เลขที่นัดหมาย: ',
  labelAppointmentDate: 'วันที่นัดหมาย: ',
  labelAppointmentTime: 'เวลา: ',
  labelAppointmentAge: 'อายุ: ',
  labelAppointmentYear: ' ปี ',
  labelAppointmentGender: '  เพศ: ',
  btnClose: 'ปิด',
  LaboratoryReport: 'รายงานผลการตรวจวิเคราะห์',
  Filter: 'กรองข้อมูล',
  map: 'แผนที่',
  labelCovidIgM: 'COVID-19 IgM : ',
  labelCovidIgG: 'COVID-19 IgG : ',
  Neg: 'Negative',
  Pos: 'Positive',
  CovidIgMNeg: 'COVID-19 IgM : Negative',
  CovidIgMPos: 'COVID-19 IgM : Positive',
  CovidIgGNeg: 'COVID-19 IgG : Negative',
  CovidIgGPos: 'COVID-19 IgG : Positive',
  result: 'ผลการตรวจ',
  labelAppID: 'เลขที่นัด: ',
  labelAppDate: 'วันที่นัด: ',
  labelAppTime: 'เวลา: ',
  labelLabNo: 'เลขที่สั่งตรวจ: ',
  labelLabRevDate: 'วันที่รับ: ',
  labelLabTime: 'เวลา: ',
  cancelAppointmentAlert: 'ยกเลิกนัดหมายเรียบร้อยแล้ว',
  labelconfirmAppointment: '(ยืนยันการนัดหมาย)',
  NoData: 'ไม่มีข้อมูลนัดหมาย',
  ourCompanyName: 'บริษัท อินโนเทค ลาบอราทอรี่ เซอร์วิส จำกัด',
  ourCompanyaddr: 'เลขที่ 697 ถ.ศรีนครินทร์ แขวงสวนหลวง เขตสวนหลวง กรุงเทพฯ 10250',
  ourCompanyaddr1: 'เลขที่ 697 ถ.ศรีนครินทร์ แขวงสวนหลวง',
  ourCompanyaddr2: 'เขตสวนหลวง กรุงเทพฯ 10250',
  ourCompanyTel: 'โทรศัพท์: 084-126-1045',
  ourCompanyFAX: 'โทรสาร: 02-320-5136',
  ourCompanyEmail: 'อีเมล: info@innotechlab.co.th',
  ourCompanyWeb: 'เว็บไซต์: HTTP://WWW.INNOTECHLAB.CO.TH/',
  NoDataOrderEntry: 'ไม่มีข้อมูล',
  qrcodeDesc: 'กรุณาแสดง QR code หรือแจ้งชื่อ-สกุล ที่จุดลงทะเบียนเพื่อรับการตรวจ',
  qrcodeDesc2: 'เจ้าหน้าที่จะติดต่อกลับเพื่อยืนยันการนัดหมาย',
  viewGoolgemap: 'ดูที่ google map',
  labelInterpretation: 'คำอธิบาย',
  labelSuggestion: 'คำแนะนำ/ข้อควรปฏิบัติ',
  labelNote: 'หมายเหตุ',
  posRed: 'ตรวจพบ',
  InterpretationData01section1: 'ตรวจไม่พบภูมิคุ้มกันชนิด IgM และ IgG',
  InterpretationData01section2: '(โอกาสการติดเชื้อน้อยหรือไม่มีการติดเชื้อ)',
  SuggestionData01: 'หากท่านมีความใกล้ชิดหรือสัมผัสผู้ป่วยโรคทางเดินหายใจก่อนหน้า ควรทำการตรวจซ้ำอีกครั้งภายใน 3-7 วัน และเว้นระยะห่างจากบุคคลอื่น (Social Distancing)',
  Note01: 'ถ้ามีไข้ไอ แนะนำตรวจหาเชื้อ COVID-19 โดยหลักการ Molecular (RT-PCR) เพิ่มเติม',
  InterpretationData02section1: 'ตรวจพบภูมิคุ้มกันชนิด IgM',
  InterpretationData02section2: '(มีโอกาสติดเชื้อ อาจอยู่ในระยะที่สามารถแพร่เชื้อโรคได้)',
  SuggestionData02: 'ควรตรวจหาเชื้อ COVID-19 เพื่อยืนยันผล โดยหลักการ Molecular (RT-PCR)',
  Note02: 'หากผลการตรวจหาเชื้อ COVID-19 โดยหลักการ Molecular (RT-PCR) ไม่พบเชื้อ ควรกักบริเวณ 14 วัน และตรวจซ้ำอีกครั้ง',
  InterpretationData03section1: 'ตรวจพบภูมิคุ้มกันชนิด IgM และ IgG',
  InterpretationData03section2: '(มีโอกาสติดเชื้อ อาจอยู่ในระยะที่สามารถแพร่เชื้อโรคได้)',
  SuggestionData03: 'ควรตรวจหาเชื้อ COVID-19 เพื่อยืนยันผล โดยหลักการ Molecular (RT-PCR)',
  Note03: 'หากผลการตรวจหาเชื้อ COVID-19 โดยหลักการ Molecular (RT-PCR) ไม่พบเชื้อ ควรกักบริเวณ 14 วัน และตรวจซ้ำอีกครั้ง',
  InterpretationData04section1: 'ตรวจพบภูมิคุ้มกันชนิด IgG',
  InterpretationData04section2: '(มีโอกาสติดเชื้อ อาจอยู่ในระยะท้ายๆ ของโรค และมีภูมิคุ้มกันต่อโรคนี้ได้)',
  SuggestionData04: 'ควรตรวจหาเชื้อ COVID-19 เพื่อยืนยันผล โดยหลักการ Molecular (RT-PCR)',
  Note04: 'หากผลการตรวจหาเชื้อ COVID-19 โดยหลักการ Molecular (RT-PCR) ไม่พบเชื้อ ควรกักบริเวณ 14 วัน และตรวจซ้ำอีกครั้ง',
  showAppointment: 'แสดงใบนัด',
  appointmentDuplicate: 'คุณได้ทำการนัดหมายวันเวลานี้แล้ว',
  appointmentDuplicate2: 'คุณได้ทำการนัดหมายแล้ว กรุณายกเลิกนัดหมายเดิมก่อน',
  mainMenu: 'หน้าจอหลัก',
  appointmentConfirmed: 'ยืนยันการนัดหมาย',
  AppointmentName: 'ผู้เข้ารับบริการ',
  detail: 'รายละเอียด',
  viewDetail: 'กดเลือกเพื่อดูผล',
  usermanual1: 'นัดหมายล่วงหน้าผ่านระบบ',
  usermanual12: '(นัดหมายล่วงหน้า)',
  usermanual2: 'นำใบนัดเข้ารับบริการ',
  usermanual3: 'ดูผลผ่านระบบ',
  usermanual32: '(รายงานผลการตรวจวิเคราะห์)',
}
