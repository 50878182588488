import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
import language from './modules/language'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('@/assets/sidebar-6.jpg'),
    drawer: false, /* Golf 2021-05-19 */
    drawerPublic: false,
    showAppBar: true,
    articles: require('@/data/articles.json'),
    items: [
      {
        text: 'สินเชื่อและบริการทางการเงิน',
        href: '#!',
      },
      {
        text: 'ค้นหาสาขา',
        href: '#!',
      },
      {
        text: 'เกี่ยวกับบริษัท',
        href: '#about',
      },
      {
        text: 'เกี่ยวกับบริษัท',
        href: '#about',
      },
      {
        text: 'ข่าวสารและบทความ',
        href: '#about',
      },
      {
        text: 'โปรโมชั่น',
        href: '#about',
      },
      {
        text: 'ติดต่อเรา',
        href: '#about',
      },
      {
        text: 'ร่วมงานกับเรา',
        href: '#about',
      },
    ],
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_DRAWER_WIDTH (state, payload) {
      state.drawerWidth = payload
    },
    SET_SHOW_APP_BAR (state, payload) {
      state.showAppBar = payload
    },
    SET_APP_BAR_HEIGHT (state, payload) {
      state.appBarHeight = payload
    },
    setDrawer: (state, payload) => (state.drawerPublic = payload),
    toggleDrawer: state => (state.drawerPublic = !state.drawerPublic),
  },
  getters: {
    getShowAppBar: state => state.showAppBar,
    categories: state => {
      const categories = []

      for (const article of state.articles) {
        if (
          !article.category ||
          categories.find(category => category.text === article.category)
        ) continue

        const text = article.category

        categories.push({
          text,
          href: '#!',
        })
      }

      return categories.sort().slice(0, 4)
    },
    links: (state, getters) => {
      /* return state.items.concat(getters.categories) */
      return state.items
    },
  },
  actions: {

  },
  modules: {
    user,
    auth,
    language,
  },
  strict: debug,
})
