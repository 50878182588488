export default {
  appointment: 'Appointment',
  myAppointment: 'My Appointment',
  report: 'Laboratory Report',
  titleMenuCovid: 'COVID-19 Service',
  DescriptonAnalysis: 'Interpretation of Analysis',
  regulation: 'Regulation',
  generalInfo: 'General Information',
  userManual: 'How to use',
  appointmentDate: 'Appointment Date',
  slotNo: 'No.',
  slotTime: 'Time',
  slotAppointmentQty: 'Limit',
  appointment2: 'Appointment',
  confirmAppointment: 'Are you sure you want to appointment {}?',
  cancelAppointment: 'Are you sure you want to cancel appointment {}?',
  labelAppointmentID: 'Appointment ID: ',
  labelAppointmentDate: 'Appointment Date:',
  labelAppointmentTime: 'Time:',
  labelAppointmentAge: 'Age: ',
  labelAppointmentYear: ' Year',
  labelAppointmentGender: '  Gender: ',
  btnClose: 'Close',
  LaboratoryReport: 'Laboratory Report',
  Filter: 'Filter',
  map: 'Map',
  labelCovidIgM: 'COVID-19 IgM : ',
  labelCovidIgG: 'COVID-19 IgG : ',
  Neg: 'Negative',
  Pos: 'Positive',
  CovidIgMNeg: 'COVID-19 IgM : Negative',
  CovidIgMPos: 'COVID-19 IgM : Positive',
  CovidIgGNeg: 'COVID-19 IgG : Negative',
  CovidIgGPos: 'COVID-19 IgG : Positive',
  result: 'Result',
  labelAppID: 'APP ID: ',
  labelAppDate: 'APP Date: ',
  labelAppTime: 'TIME: ',
  labelLabNo: 'Lab No: ',
  labelLabRevDate: 'REV Date: ',
  labelLabTime: 'TIME: ',
  cancelAppointmentAlert: 'Cancel appointment complete.',
  labelconfirmAppointment: '(Confirm appointment)',
  NoData: 'No data.',
  ourCompanyName: 'INNOTECH LABORATORY SERVICES CO., LTD.',
  ourCompanyaddr: '697 SRINAKARIN ROAD, SUAN LUANG, BANGKOK 10250',
  ourCompanyaddr1: '697 Srinakarin Rd., Suan Luang,',
  ourCompanyaddr2: 'Bangkok 10250',
  ourCompanyTel: 'TEL: 084-126-1045',
  ourCompanyFAX: 'FAX: 02-320-5136',
  ourCompanyEmail: 'E-MAIL: INFO@INNOTECHLAB.CO.TH',
  ourCompanyWeb: 'WEBSITE: HTTP://WWW.INNOTECHLAB.CO.TH/',
  NoDataOrderEntry: 'No data.',
  qrcodeDesc: 'Please show your QR code at registration.',
  qrcodeDesc2: 'We will contact you soon to confirm your appointment',
  viewGoolgemap: 'view google map',
  labelInterpretation: 'Interpretation',
  labelSuggestion: 'Suggestion',
  labelNote: 'Note',
  posRed: 'DETECTED',
  InterpretationData01section1: 'NOT DETECTED COVID-19 antibody',
  InterpretationData01section2: '(Little or no chance of  COVID-19 infection)',
  SuggestionData01: 'If in close contact with someone who had respiratory tract illness, you should practice "Social Distancing" and retest again in 3-7 days for confirmation',
  Note01: 'If you display symptoms, proceed for further testing to detect presence of COVID-19 virus through molecular testing (RT-PCR)',
  InterpretationData02section1: 'COVID-19 IgM antibody DETECTED',
  InterpretationData02section2: '(Chance of COVID-19 Infection May be an asymptomatic carrier)',
  SuggestionData02: 'Should proceed for further testing to detect presence of COVID-19 virus through molecular testing (RT-PCR)',
  Note02: 'If COVID-19 undetected by molecular testing, must self-quarantine for 14 days and retest again',
  InterpretationData03section1: 'COVID-19 IgM and IgG antibody DETECTED',
  InterpretationData03section2: '(Chance of COVID-19 Infection May be an asymptomatic carrier)',
  SuggestionData03: 'Should proceed for further testing to detect presence of COVID-19 virus through molecular testing (RT-PCR)',
  Note03: 'If COVID-19 undetected by molecular testing, must self-quarantine for 14 days and retest again',
  InterpretationData04section1: 'COVID-19 IgG antibody DETECTED',
  InterpretationData04section2: '(Chance of infection OR in the last stage of infection and have immunity to COVID-19)',
  SuggestionData04: 'Should proceed for further testing to detect presence of COVID-19 virus through molecular testing (RT-PCR)',
  Note04: 'If COVID-19 undetected by molecular testing, must self-quarantine for 14 days and retest again',
  showAppointment: 'Show Appointment',
  appointmentDuplicate: 'Appointment date is Duplicate.',
  appointmentDuplicate2: 'You have an appointment. Please cancel the appointment list.',
  mainMenu: 'Main Menu',
  appointmentConfirmed: 'Appointment Confirmed',
  AppointmentName: 'Appointment Name',
  detail: 'Detail',
  viewDetail: 'View Detail',
  usermanual1: 'Make Appointment',
  usermanual12: '(Appointment)',
  usermanual2: 'Show your appointment card and get service',
  usermanual3: 'View your results via system',
  usermanual32: '(Laboratory report)',
}
