export default {
  applicationForm: 'สมัครสินเชื่อ',
  product: 'ผลิตภัณฑ์',
  firstName: 'ชื่อ',
  lastName: 'นามสกุล',
  Tel: 'เบอร์โทรศัพท์',
  provinces: 'จังหวัด',
  amphur: 'อำเภอ/เขต',
  tambon: 'ตำบล/แขวง',
  EMAIL: 'อีเมล',
  LINE: 'ไลน์',
  RefCode: 'รหัสอ้างอิง',
  AcceptPolicy: 'ยอมรับ นโยบายคุ้มครองข้อมูลส่วนบุคคล',
  RejectPolicy: 'ปฎิเสธ นโยบายคุ้มครองข้อมูลส่วนบุคคล',
  Policy: 'ข้าพเจ้าให้ความยินยอมกับบริษัทฯ ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าในการติดต่อเพื่อเสนอผลิตภัณฑ์และบริการของบริษัทฯและรับรองว่าได้อ่านและรับทราบประกาศความเป็นส่วนตัวแล้ว',
  SEND: 'ส่ง',
  Title: 'แก้ไขข้อมูลการสมัคร',
  messageProduct: 'กรุณาเลือกผลิตภัณฑ์',
  messageName: 'กรุณาใส่ชื่อนามสกุล',
  messageTel: 'กรุณาใส่เบอร์โทร',
  messageProvince: 'กรุณาเลือกจังหวัด',
  messageDistrict: 'กรุณาเลือกอำเภอ',
  messageSubDistrict: 'กรุณาเลือกตำบล',
  messagePolicy: 'กรุณายอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคล',
  formHeader: 'แบบฟอร์มการสมัคร',
  messageMandatory: 'กรุณากรอกข้อความในช่องที่มีเครื่องหมายดอกจันสีแดง',
  uploadTitle: 'นำเข้าเอกสารการสมัคร',
  addFile: 'เพิ่มเอกสาร',
  Status: 'สถานะ',
  idCard: 'เลขประจำตัวประชาชน',
  prefix: 'คำนำหน้าชื่อ',
  HouseNo: 'บ้านเลขที่',
  Moo: 'หมู่ที่',
  Trog: 'ตรอก',
  Soi: 'ซอย',
  Road: 'ถนน',
  birthDay: 'วันเกิด',
  NewRegister: 'เพิ่มข้อมูลการสมัคร',
  Individual: 'บุคคลธรรมดา',
  Company: 'นิติบุคคล',
  companyName: 'ชื่อบริษัท',
  companyId: 'เลขนิติบุคคล',
  contactPersonFirstName: 'ชื่อผู้ติดต่อ',
  contactPersonLastName: 'นามสกุลผู้ติดต่อ',
  uploadFile: 'อัพโหลด',
  downloadFile: 'ดาวน์โหลด .zip',
  docName: 'ชื่อเอกสาร',
  TelContact: 'เบอร์โทรผู้ติดต่อ',
  remark: 'หมายเหตุ',
  fileName: 'ชื่อไฟล์',
  DeleteFile: 'ลบไฟล์',
  DownloadFile: 'ดาวน์โหลดไฟล์',
  PreviewFile: 'ดูไฟล์',
  confirmDeleteSelected: 'ต้องการลบไฟล์หรือไม่ ?',
  Ok: 'ตกลง',
  Cancel: 'ยกเลิก',
  readIdCard: 'ดึงข้อมูลบัตรประชาชน',
  findData: 'ค้นหา',
  addData: 'เพิ่ม',
  clearData: 'เคลียร์',
  CreditLimit: 'วงเงินที่ขอสินเชื่อ',
  Guarantee: 'ประเภทหลักประกัน',
  GuaranteeProvince: 'จังหวัดที่ตั้งหลักประกัน',
  Reject: 'เหตุผลการยกเลิก/ปฏิเสธ',
  DeedNo: 'เลขที่โฉนด',
  ConvenientTime: 'ช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  ReceiveChannel: 'ช่องทางที่ได้รับข่าวสารบริษัท',
  companyPrefix: 'คำนำหน้าบริษัท',
  addRemark: 'เพิ่มหมายเหตุ',
  dateRemark: 'วันที่หมายเหตุ',
}
