export default {
  userName: 'User Name',
  password: 'Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  login: 'Log in',
  register: 'Registration',
  titleRegister: 'Registration',
  mobileNo: '*Mobile No.',
  cancel: 'Close',
  save: 'Save',
  userNameForm: '*User Name',
  firstName: '*First Name',
  middleName: 'Middle Name',
  lastName: '*Last Name',
  anonymousName: '*Anonymous Name',
  anonymousFlag: 'Show Anonymous',
  birthDate: '*Birth Date',
  gender: 'Gender',
  eMail: 'Email',
  condition: 'Terms & Conditions',
  conditionHeader: 'Terms and conditions',
  condition1: '1. You must show the right information and/or the personal data which might identify and prove to your information. The company will not responsible for any mistake from inaccurate or misleading.',
  condition2: '2. You must use your information to apply for services only. The company will not responsible for any mistake from the impersonation of other information.',
  condition3: '3. Your information for the application/website must be accurate for noninfringement and legally.',
  condition4: '4. In case, you want to conceal your information (name and surname), you can do follow the application/website conditions.',
  condition5: '5. The service provider will not responsible for any mistake from your using error.',
  condition6: '6. The service provider unwarranted for the website information and services will reach all user expectations. Also, unwarranted for ceasing providing all or part of the services without any prior notice (include error, bug, and delay) to the user, and unpromised for instantly solving. You should decide to use the application/website by yourself and accept any risk that might happen to your devices or data loss caused by application/website which cannot claim for any damages which are related to providing services.',
  condition7: '7. Accepting of terms and conditions include allowing services provider (Innotech Laboratory Services Company Limited) accesses your information and storage your personal data without further permission in the future.',
  agreeCondition: 'Agreement terms & conditions',
  userNameFormHint: '[A-Z] or [0-9] limit 30',
  messageUserName: '*Please enter User Name.',
  messageAnonymous: '*Please enter anonymous Name.',
  messageFirstName: '*Please enter first name.',
  messageLastName: '*Please enter last name.',
  messageBirthDate: '*Please enter Birthdate.',
  messageGender: '*Please enter gender.',
  messageMobile: '*Please enter mobile no.',
  messageAgreeCondition: '*Please agree conditions and agreement.',
  messagePassword: '**Please enter new password',
  DescriptionPassword: 'default password is your birthdate (ddmmyyyy ex.01122000)',
  Agree: 'Agree',
  Disagree: 'Disagree',
  questionnaire: 'Questionnaire',
  confirm: 'Confirm',
  pleaseQuestionnaire: 'Please fill out the Questionnaire.',
  yesQuestionnaire: 'Yes',
  noQuestionnaire: 'No',
  Questionnaire1: '1. Have you lived, traveled or returned from aboard where COVID-19 pandemic (to include your relative and close person)?  ',
  Questionnaire2: '2. Do you have any relative or close person who got pneumonia or respiratory illness?',
  Questionnaire3: '3. Have you been in close contact with anyone who got pneumonia / respiratory illness? Or have you been in close contact with anyone who suspected of having COVID-19 infection or ill with confirmed COVID-19 infection?',
  Questionnaire4: '4. Do you have a job which contacts the traveler or crowed people/place?',
  Questionnaire5: '5. Have you experienced any cold or flu-like symptoms in the last 14 days (to include fever, cough, sore throat, respiratory illness, difficulty breathing)?',
  invalidEmail: 'Invalid Email address',
  female: 'Female',
  male: 'Male',
  birthDateFormat: 'DD/MM/YYYY format',
  contractOur: 'Contact Admin',
  RegisterComplete: 'Register Complete. ',
  ShowPassword: 'Your User Name is the mobile number  "{}", Your password is the date of birth. "{}"',
  browse: 'Browse',
  logoutMessage: 'Are you sure you want to log out?',
  passwordHint: '',
  passwordHintNew: '',
  configNewPassword: 'Setup New Password',
  forgotPassword: 'Forgot Password',
  forgotPasswordNext: 'Next',
  messageEmailUser: 'Email not found. please contract Admin.',
  confirmInfoEmail: 'Information sent to email.',
  confirmInfoEmail2: 'Please confirm.',
  messageUserInvalid: 'Invalid User',
  close: 'Close',
  configNewPasswordSuccess: 'Change password success and Do you want to login?',
  ShowNewPassword: 'Your new password is "{}"',
  back: 'Back',
  phone: 'Phone',
  registerDesc: 'Create New Account',
  loginDesc: 'Sign in',
  next: 'Next',
  messageQuestionnaire1: '*Please enter questionnaire number 1',
  messageQuestionnaire2: '*Please enter questionnaire number 2',
  messageQuestionnaire3: '*Please enter questionnaire number 3',
  messageQuestionnaire4: '*Please enter questionnaire number 4',
  messageQuestionnaire5: '*Please enter questionnaire number 5',
  testCovid: 'COVID-19 Testing',
  invalidPassword: 'รหัสผ่านต้องประกอบไปด้วยอักษรใหญ่+เล็ก+ตัวเลข+อักขระพิเศษ และไม่น้อยกว่า 8 ตัว',
}
