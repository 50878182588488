export default {
  applicationFormPerson: 'Register - Person',
  applicationFormCompany: 'Register - Company',
  product: 'Product',
  firstName: 'First Name',
  lastName: 'Last Name',
  Tel: 'Phone',
  provinces: 'Province',
  amphur: 'District',
  tambon: 'Sub District',
  EMAIL: 'Email',
  LINE: 'Line',
  RefCode: 'Ref Code',
  AcceptPolicy: 'Accept Policy',
  RejectPolicy: 'Reject Policy',
  Policy: 'ข้าพเจ้าให้ความยินยอมกับบริษัทฯ ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าในการติดต่อเพื่อเสนอผลิตภัณฑ์และบริการของบริษัทฯและรับรองว่าได้อ่านและรับทราบประกาศความเป็นส่วนตัวแล้ว',
  SEND: 'Send',
  messageProduct: 'Please Enter Product',
  messageName: 'Please Enter Name',
  messageTel: 'Please Enter Phone Number',
  messageProvince: 'Please Enter Province',
  messageDistrict: 'Please Enter District',
  messageSubDistrict: 'Please Enter Sub District',
  messagePolicy: 'Please Enter Policy',
  formHeader: 'Application Form',
  messageMandatory: 'Please Enter Field *',
  uploadTitle: 'Upload Register Files',
  OTP: 'One Time Password',
  idCard: 'ID Card',
  ContactCompany: 'กรณีลูกค้านิติบุคคล กรุณาติดต่อบริษัท มีที่ มีเงิน จำกัด โทร 02-025-6999 หรือธนาคารออมสินสาขาใกล้บ้านท่าน',
  companyName: 'Company Name',
  companyId: 'Company ID',
  companyContactFirstName: 'Contact First Name',
  companyContactLastName: 'Contact Last Name',
  companyContactPhone: 'Contact Phone',
  companyContactEmail: 'Contact Email',
  DeedNo: 'Deed No',
  ConvenientTime: 'Convenient Time',
  prefix: 'Prefix',
  companyPrefix: 'Company Prefix',
}
