export default {
  viewReport: '報告書',
  viewHistory: '歴史',
  registerNo: 'Register No',
  revNo: 'Rev No',
  hospitalLabNo: 'Hospital Lab No',
  pathoNo: 'Patho No',
  prefix: 'プレフィックス',
  name: '名前',
  hn: 'HN',
  physicianName: 'Physician Name',
  customer: 'お客様',
  receivedDate: '受領日',
  dateReported: '報告日',
  dueDate: 'Due Date',
  tat: 'Turn Around Time',
  labelDateFrom: 'Receive Date From',
  labelDateTo: 'Receive Date To',
  labelBtnFind: '検索',
  labelPathoPendingList: 'Pathologist Pending Report',
  labelPathoCompleteList: 'Pathologist Complete Report',
  labelPathoHistory: 'Pathology History Report',
}
