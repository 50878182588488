export default {
  ConvenientTimeTitle: 'จัดการช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  buttonnewConvenientTime: 'เพิ่มช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  buttonnewConvenientTimeLanguage: 'เพิ่มภาษาช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  buttonDeleteConvenientTime: 'ลบช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  buttonDeleteConvenientTimeLanguage: 'ลบภาษาช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  newConvenientTime: 'เพิ่มช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  editConvenientTime: 'แก้ไขช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  newConvenientTimeLanguage: 'เพิ่มภาษาช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  editConvenientTimeLanguage: 'แก้ไขภาษาช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  convenientTimeName: 'ชื่อช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  confirmDelete: 'คุณต้องการที่จะลบช่วงเวลาที่สะดวกให้ติดต่อกลับ {}?',
  confirmConvenientTimeLanguageDelete: 'คุณต้องการที่จะลบช่วงเวลาที่สะดวกให้ติดต่อกลับภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบช่วงเวลาที่สะดวกให้ติดต่อกลับที่เลือก?',
  confirmConvenientTimeLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาช่วงเวลาที่สะดวกให้ติดต่อกลับที่เลือก?',
  progressMessageDelete: 'กำลังลบช่วงเวลาที่สะดวกให้ติดต่อกลับ {}',
  progressMessageConvenientTimeLanguageDelete: 'กำลังลบภาษาช่วงเวลาที่สะดวกให้ติดต่อกลับ {}',
  messageDeleteNotSelected: 'กรุณาเลือกช่วงเวลาที่สะดวกให้ติดต่อกลับที่จะลบ',
  messagedeleteLanguageNotSelected: 'กรุณาเลือกภาษาช่วงเวลาที่สะดวกให้ติดต่อกลับที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  convenientTimeDetail: 'รายละเอียดช่วงเวลาที่สะดวกให้ติดต่อกลับ',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
}
