import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from '../actions/user'
// import apiCall from 'utils/api'
import Vue from 'vue'
import { AUTH_LOGOUT } from '../actions/auth'
import axios from 'axios'

const state = { status: '', profile: {}, username: localStorage.getItem('username') }
const userInfo = { username: localStorage.getItem('username'), userToken: localStorage.getItem('user-token') }

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.first_name,
}

const actions = {
  [USER_REQUEST]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      commit(USER_REQUEST)

      // apiCall({url: 'user/me'})
      //   .then(resp => {
      //     commit(USER_SUCCESS, resp)
      //   })
      //   .catch(resp => {
      //     commit(USER_ERROR)
      //     // if resp is unauthorized, logout, to
      //     dispatch(AUTH_LOGOUT)
      //   })

      userInfo.username = localStorage.getItem('username')
      // userInfo.password = localStorage.getItem('password')
      userInfo.userToken = localStorage.getItem('user-token')

      axios.defaults.headers.common['Authorization'] = `Bearer-${userInfo.userToken}`

      axios({url: process.env.VUE_APP_API + '/user/getUserInfo' + process.env.VUE_APP_DATABASE_TYPE, data: {user_id: userInfo.username}, method: 'POST'})
      .then(resp => {
        if (resp.data.isSuccess) {
          commit(USER_SUCCESS, resp.data.data)
          resolve(resp)
        } else {
          // commit(USER_ERROR)
          // if resp is unauthorized, logout, to
          // dispatch(AUTH_LOGOUT)
          commit(USER_ERROR)
          var error = new Error(resp.data.reasonText)
          reject(error)
        }
      })
      .catch(err => {
        // commit(USER_ERROR, err)
        // if resp is unauthorized, logout, to
        // dispatch(AUTH_LOGOUT)

        commit(USER_ERROR)
        reject(err)
      })
    })
  },
}

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [USER_SUCCESS]: (state, userData) => {
    state.status = 'success'
    Vue.set(state, 'profile', userData)
  },
  [USER_ERROR]: (state) => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
