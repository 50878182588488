export default {
  nodeTitle: 'Node',
  buttonNewNode: 'New Node',
  buttonNewNodeLanguage: 'New Node Language',
  buttonDeleteNode: 'Delete Node',
  buttonDeleteNodeLanguage: 'Delete Node Language',
  newNode: 'New Node',
  editNode: 'Edit Node',
  newNodeLanguage: 'New Node Language',
  editNodeLanguage: 'Edit Node Language',
  nodeType: 'Node Type',
  nodeWindow: 'Window',
  nodeName: 'Node Name',
  nodeComment: 'Node Comment',
  confirmDelete: 'Are you sure you want to delete Node {}?',
  confirmNodeLanguageDelete: 'Are you sure you want to delete Node Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Node?',
  confirmNodeLanguageDeleteSelected: 'Are you sure you want to delete selected Node Language?',
  progressMessageDelete: 'Deleting Node {}',
  progressMessageNodeLanguageDelete: 'Deleting Node Language {}',
  messageDeleteNotSelected: 'Please select Node to delete',
  messageDeleteNodeLanguageNotSelected: 'Please select Node Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
}
