export default {
  UserInformationTitle: 'User Information',
  buttonNewBranchBcp: 'New User Information',
  buttonNewBranchBcpLanguage: 'New User Information Language',
  buttonImport: 'Import',
  buttonImportLanguage: 'Import Language',
  newBranchBcp: 'New User Information',
  editBranchBcp: 'Edit User Information',
  newBranchBcpLanguage: 'New User Information Language',
  editBranchBcpLanguage: 'Edit User Information Language',
  name: 'User Information Name',
  confirmImport: 'Are you sure you want to Import?',
  confirmBranchBcpLanguageDelete: 'Are you sure you want to Import Language {}?',
  confirmImportSelected: 'Are you sure you want to delete selected User Information?',
  confirmBranchBcpLanguageDeleteSelected: 'Are you sure you want to delete selected User Information Language?',
  progressMessageDelete: 'Deleting User Information {}',
  progressMessageBranchBcpLanguageDelete: 'Deleting User Information Language {}',
  messageDeleteNotSelected: 'Please select User Information to delete',
  messageDeleteBranchBcpLanguageNotSelected: 'Please select User Information Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  BranchBcpDetail: 'User Information Detail',
  copyHeader: 'Copy From Header',
  street1: 'Address',
  tel: 'Tel.',
  messageSelectFile: 'Please select file',
}
