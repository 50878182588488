export default {
  ConvenientTimeTitle: 'Convenient Time',
  buttonnewConvenientTime: 'New Convenient Time',
  buttonnewConvenientTimeLanguage: 'New Convenient Time Language',
  buttonDeleteConvenientTime: 'Delete Convenient Time',
  buttonDeleteConvenientTimeLanguage: 'Delete Convenient Time Language',
  newConvenientTime: 'New Convenient Time',
  editConvenientTime: 'Edit Convenient Time',
  newConvenientTimeLanguage: 'New Convenient Time Language',
  editConvenientTimeLanguage: 'Edit Convenient Time Language',
  convenientTimeName: 'Convenient Time Name',
  confirmDelete: 'Are you sure you want to delete Convenient Time {}?',
  confirmConvenientTimeLanguageDelete: 'Are you sure you want to delete Convenient Time Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Convenient Time?',
  confirmConvenientTimeLanguageDeleteSelected: 'Are you sure you want to delete selected Convenient Time Language?',
  progressMessageDelete: 'Deleting Convenient Time {}',
  progressMessageConvenientTimeLanguageDelete: 'Deleting Convenient Time Language {}',
  messageDeleteNotSelected: 'Please select Convenient Time to delete',
  messagedeleteLanguageNotSelected: 'Please select Convenient Time Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  convenientTimeDetail: 'Convenient Time Detail',
  copyHeader: 'Copy From Header',
}
