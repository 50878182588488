<style lang="scss" scoped>
  a {
    color: white;
    text-decoration: none;
  }
  .navigation {
    display: flex;
    color: white;
    align-items: center;
    background-color: #ffa035;
    padding: 5px;

    ul{
      display: flex;
      &:first-child{
        flex-grow: 1;
      }
      li {
        padding-right: 1em;
      }
    }
  }
  .brand {
    display: flex;
    align-items: center;

  }
  .logout {
    &:hover {
      cursor: pointer;
    }
  }

</style>

<script>
  import { AUTH_LOGOUT } from 'actions/auth'

  export default {
    name: 'logout',
    created: function () {
      this.logout()
      /* this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login')) */
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/'))
    },
    methods: {
      logout () {
        console.log('logout**')
        var base64ParmUser = btoa(this.usernameForgot)
        console.log(base64ParmUser, 'base64ParmUser')
        this.axios.post(process.env.VUE_APP_API + '/user/logout', {
          user_id: '',
        })
          .then(response => {
            if (response.data.isSuccess) {
              /* location.reload() */
              console.log(response.data.data, 'response.data.data')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
