export default {
  DocumentDetailTitle: 'จัดการเอกสาร',
  buttonnewDocumentDetail: 'เพิ่มเอกสาร',
  buttonnewDocumentDetailLanguage: 'เพิ่มภาษาเอกสาร',
  buttonDeleteDocumentDetail: 'ลบเอกสาร',
  buttonDeleteDocumentDetailLanguage: 'ลบภาษาเอกสาร',
  newDocumentDetail: 'เพิ่มเอกสาร',
  editDocumentDetail: 'แก้ไขเอกสาร',
  newDocumentDetailLanguage: 'เพิ่มภาษาเอกสาร',
  editDocumentDetailLanguage: 'แก้ไขภาษาเอกสาร',
  DocumentDetailName: 'ชื่อเอกสาร',
  confirmDelete: 'คุณต้องการที่จะลบเอกสาร {}?',
  confirmDocumentDetailLanguageDelete: 'คุณต้องการที่จะลบเอกสารภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบเอกสารที่เลือก?',
  confirmDocumentDetailLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาเอกสารที่เลือก?',
  progressMessageDelete: 'กำลังลบเอกสาร {}',
  progressMessageDocumentDetailLanguageDelete: 'กำลังลบภาษาเอกสาร {}',
  messageDeleteNotSelected: 'กรุณาเลือกเอกสารที่จะลบ',
  messageDeleteDocumentDetailLanguageNotSelected: 'กรุณาเลือกภาษาเอกสารที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  DocumentDetailDetail: 'รายละเอียดเอกสาร',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  messagePleaseEnterArticleName: 'กรุณากรอกชื่อเอกสาร',
  messagePleaseEnterArticleDetail: 'กรุณากรอกรายละเอียดเอกสาร',
  messagePleaseEnterDocumentHeader: 'กรุณาเลือกกลุ่มเอกสาร',
  ChooseFile: 'เลือกไฟล์',
  FileName: 'ชื่อไฟล์',
  remark: 'หมายเหตุ',
  Download: 'Download',
  messagePleaseSelectFileUpload: 'กรุณาเลือกไฟล์นำเข้า',
}
