export default {
  userMaintTitle: 'User Maintenance',
  buttonNewUser: 'New User',
  userID: 'User ID',
  picture: 'Picture',
  firstName: 'First Name',
  lastName: 'Last Name',
  employeeNo: 'Employee No',
  facilityCode: 'Facility Code',
}
