export default {
  viewReport: 'รายงานผล',
  viewHistory: 'ประวัติ',
  registerNo: 'Register No',
  revNo: 'Rev No',
  hospitalLabNo: 'Hospital Lab No',
  pathoNo: 'Patho No',
  prefix: 'คำนำหน้า',
  name: 'ชื่อนามสกุล',
  hn: 'HN',
  physicianName: 'Physician Name',
  customer: 'ลูกค้า',
  receivedDate: 'วันที่รับ',
  dateReported: 'วันที่รายงาน',
  dueDate: 'Due Date',
  tat: 'Turn Around Time',
  labelDateFrom: 'Receive Date From',
  labelDateTo: 'Receive Date To',
  labelBtnFind: 'ค้นหา',
  labelPathoPendingList: 'Pathologist Pending Report',
  labelPathoCompleteList: 'Pathologist Complete Report',
  labelPathoHistory: 'Pathology History Report',
  labelPathoCytologistPendingList: 'Cytologist Pending Report',
  labelPathoCytologistCompleteList: 'Cytologist Complete Report',
  labelPathoGrossPendingList: 'Gross Pending Report',
}
