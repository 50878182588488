export default {
  PromotionsTitle: 'จัดการโปรโมชั่น',
  buttonnewPromotions: 'เพิ่มโปรโมชั่น',
  buttonnewPromotionsLanguage: 'เพิ่มภาษาโปรโมชั่น',
  buttonDeletePromotions: 'ลบโปรโมชั่น',
  buttonDeletePromotionsLanguage: 'ลบภาษาโปรโมชั่น',
  newPromotions: 'เพิ่มโปรโมชั่น',
  editPromotions: 'แก้ไขโปรโมชั่น',
  newPromotionsLanguage: 'เพิ่มภาษาโปรโมชั่น',
  editPromotionsLanguage: 'แก้ไขภาษาโปรโมชั่น',
  promotionsName: 'ชื่อโปรโมชั่น',
  confirmDelete: 'คุณต้องการที่จะลบโปรโมชั่น {}?',
  confirmPromotionsLanguageDelete: 'คุณต้องการที่จะลบโปรโมชั่นภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบโปรโมชั่นที่เลือก?',
  confirmPromotionsLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาโปรโมชั่นที่เลือก?',
  progressMessageDelete: 'กำลังลบโปรโมชั่น {}',
  progressMessagePromotionsLanguageDelete: 'กำลังลบภาษาโปรโมชั่น {}',
  messageDeleteNotSelected: 'กรุณาเลือกโปรโมชั่นที่จะลบ',
  messageDeletePromotionsLanguageNotSelected: 'กรุณาเลือกภาษาโปรโมชั่นที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  promotionsDetail: 'รายละเอียดโปรโมชั่น',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  messagePleaseEnterPromotionsName: 'กรุณากรอกชื่อโปรโมชั่น',
  messagePleaseEnterPromotionsDetail: 'กรุณากรอกรายละเอียดโปรโมชั่น',
}
