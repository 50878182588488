export default {
  CreditLimitTitle: 'Credit Limit',
  buttonnewCreditLimit: 'New Credit Limit',
  buttonnewCreditLimitLanguage: 'New Credit Limit Language',
  buttonDeleteCreditLimit: 'Delete Credit Limit',
  buttonDeleteCreditLimitLanguage: 'Delete Credit Limit Language',
  newCreditLimit: 'New Credit Limit',
  editCreditLimit: 'Edit Credit Limit',
  newCreditLimitLanguage: 'New Credit Limit Language',
  editCreditLimitLanguage: 'Edit Credit Limit Language',
  creditLimitName: 'Credit Limit Name',
  confirmDelete: 'Are you sure you want to delete Credit Limit {}?',
  confirmCreditLimitLanguageDelete: 'Are you sure you want to delete Credit Limit Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Credit Limit?',
  confirmCreditLimitLanguageDeleteSelected: 'Are you sure you want to delete selected Credit Limit Language?',
  progressMessageDelete: 'Deleting Credit Limit {}',
  progressMessageCreditLimitLanguageDelete: 'Deleting Credit Limit Language {}',
  messageDeleteNotSelected: 'Please select Credit Limit to delete',
  messagedeleteLanguageNotSelected: 'Please select Credit Limit Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  creditLimitDetail: 'Credit Limit Detail',
  copyHeader: 'Copy From Header',
  isIndividual: 'Individual',
  isCompany: 'Company',
  creditLimitMin: 'Credit Limit Minimum',
  creditLimitMax: 'Credit Limit Maximum',
  productName: 'Prduct Name'
}
