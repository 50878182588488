export default {
  SubscribeTitle: 'จัดการข้อมูลการติดต่อ',
  buttonnewSubscribe: 'เพิ่มการติดต่อ',
  buttonnewSubscribeLanguage: 'เพิ่มภาษาการติดต่อ',
  buttonDeleteSubscribe: 'ลบการติดต่อ',
  buttonDeleteSubscribeLanguage: 'ลบภาษาการติดต่อ',
  newSubscribe: 'เพิ่มการติดต่อ',
  editSubscribe: 'แก้ไขการติดต่อ',
  newSubscribeLanguage: 'เพิ่มภาษาการติดต่อ',
  editSubscribeLanguage: 'แก้ไขภาษาการติดต่อ',
  SubscribeName: 'ชื่อการติดต่อ',
  confirmDelete: 'คุณต้องการที่จะลบการติดต่อ {}?',
  confirmSubscribeLanguageDelete: 'คุณต้องการที่จะลบการติดต่อภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบการติดต่อที่เลือก?',
  confirmSubscribeLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาการติดต่อที่เลือก?',
  progressMessageDelete: 'กำลังลบการติดต่อ {}',
  progressMessageSubscribeLanguageDelete: 'กำลังลบภาษาการติดต่อ {}',
  messageDeleteNotSelected: 'กรุณาเลือกการติดต่อที่จะลบ',
  messageDeleteSubscribeLanguageNotSelected: 'กรุณาเลือกภาษาการติดต่อที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  SubscribeDetail: 'รายละเอียดการติดต่อ',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  policyFlag: 'ยอมรับนโยบายส่วนบุคคล',
  subscribeText: 'ข้อความที่ส่งมา',
  subscribeHeader: 'เรื่องที่ติดต่อ',
  name: 'ชื่อ-นามสกุล',
  email: 'อีเมล',
  tel: 'เบอร์โทรศัพท์',
  messageName: 'กรุณาใส่ชื่อนามสกุล',
  messageEmail: 'กรุณาใส่อีเมล',
  messageTel: 'กรุณาใส่เบอร์โทร',
  messageSubscribeMessage: 'กรุณาใส่ข้อความที่ต้องการติดต่อ',
  messageSubscribeHeaderMessage: 'กรุณาใส่เรื่องที่ต้องการติดต่อ',
  messagePolicy: 'กรุณายอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคล',
}
