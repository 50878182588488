import store from '../store'
import Login from '@/components/login'
import Logout from '@/components/logout'
import Dashboard2 from '@/views/dashboard/Dashboard2'
import Home from '../views/Home.vue'
import ForgotPassword from 'components/login/ForgotPassword'
var ifNotAuthenticated = true
const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    ifNotAuthenticated = true
    next()
    return
  }
  ifNotAuthenticated = false
  next('/')
  /* next('/Home') */
}

export default [
  /* {
    name: 'Dashboard',
    path: '',
    component: () => import('@/views/dashboard/Dashboard'),
    // beforeEnter: ifNotAuthenticated,
  }, */
  {
    path: '/login',
    name: 'Login',
    component: Login,
    // beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    /* beforeEnter: ifNotAuthenticated, */
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    beforeEnter: ifAuthenticated,
    children: [
      // Dashboard
      /* First component */
      /* {
        name: 'Dashboard',
        path: '/',
        component: () => import('@/views/dashboard/Dashboard'),
      }, */
      {
        name: 'DashboardMt',
        path: '/DashboardMt',
        component: () => import('@/components/DashboardMt'),
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('components/user'),
      },
      {
        path: '/Account',
        name: 'Account',
        component: () => import('components/account'),
      },
      {
        path: '/NavWindow',
        name: 'NavWindow',
        component: () => import('@/components/navigation/NavWindow'),
      },
      {
        path: '/NavNode',
        name: 'NavNode',
        component: () => import('@/components/navigation/NavNode'),
      },
      {
        path: '/NavRoute',
        name: 'NavRoute',
        component: () => import('@/components/navigation/NavRoute'),
      },
      {
        path: '/JasperReportExample',
        name: 'JasperReportExample',
        component: () => import('@/components/Example/JasperReportExample'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/ExcelViewerExample',
        name: 'ExcelViewerExample',
        component: () => import('@/components/Example/ExcelViewerExample'),
      },
      {
        path: '/DashboardExample',
        name: 'DashboardExample',
        component: () => import('@/components/Example/DashboardExample'),
      },
      {
        path: '/PdfViewerExample',
        name: 'PdfViewerExample',
        component: () => import('@/components/Example/PdfViewerExample'),
      },
      {
        path: '/UploadToMongoDBExample',
        name: 'UploadToMongoDBExample',
        component: () => import('@/components/Example/UploadToMongoDBExample'),
      },
      {
        path: '/SchedulerExample',
        name: 'SchedulerExample',
        component: () => import('@/components/Example/SchedulerExample'),
      },
      {
        path: '/GoogleMapExample',
        name: 'GoogleMapExample',
        component: () => import('@/components/Example/GoogleMapExample'),
      },
      {
        path: '/ChartExample',
        name: 'ChartExample',
        component: () => import('@/components/Example/ChartExample'),
      },
      {
        path: '/ArticlesMaint',
        name: 'ArticlesMaint',
        component: () => import('@/components/mtmng/ArticlesMaint'),
      },
      {
        path: '/SubscribeMaint',
        name: 'SubscribeMaint',
        component: () => import('@/components/mtmng/SubscribeMaint'),
      },
      {
        path: '/BranchGSBMaint',
        name: 'BranchGSBMaint',
        component: () => import('@/components/mtmng/BranchGSBMaint'),
      },
      {
        path: '/BranchBCPMaint',
        name: 'BranchBCPMaint',
        component: () => import('@/components/mtmng/BranchBCPMaint'),
      },
      {
        path: '/BranchTIPMaint',
        name: 'BranchTIPMaint',
        component: () => import('@/components/mtmng/BranchTIPMaint'),
      },
      {
        path: '/DashboardMt',
        name: 'DashboardMtPage',
        component: () => import('@/components/DashboardMt'),
      },
      {
        path: '/RegisterMaint',
        name: 'RegisterMaint',
        component: () => import('@/components/mtmng/RegisterMaint'),
      },
      {
        path: '/RegisterMaintPartner',
        name: 'RegisterMaintPartner',
        component: () => import('@/components/mtmng/RegisterMaintPartner'),
      },
      {
        path: '/PromotionsMaint',
        name: 'PromotionsMaint',
        component: () => import('@/components/mtmng/PromotionsMaint'),
      },
      {
        path: '/workWithUsMaint',
        name: 'workWithUsMaint',
        component: () => import('@/components/mtmng/workWithUsMaint'),
      },
      {
        path: '/ProductMaint',
        name: 'ProductMaint',
        component: () => import('@/components/mtmng/ProductMaint'),
      },
      {
        path: '/ProductsMaint',
        name: 'ProductsMaint',
        component: () => import('@/components/mtmng/ProductsMaint'),
      },
      {
        path: '/ConfigOtherMaint',
        name: 'ConfigOtherMaint',
        component: () => import('@/components/mtmng/ConfigOtherMaint'),
      },
      {
        path: '/DocumentHeaderMaint',
        name: 'DocumentHeaderMaint',
        component: () => import('@/components/mtmng/DocumentHeaderMaint'),
      },
      {
        path: '/DocumentDetailMaint',
        name: 'DocumentDetailMaint',
        component: () => import('@/components/mtmng/DocumentDetailMaint'),
      },
      {
        path: '/UploadMaint',
        name: 'UploadMaint',
        component: () => import('@/components/mtmng/UploadMaint'),
      },
      {
        path: '/userGroup',
        name: 'userGroup',
        component: () => import('components/userGroup'),
      },
      {
        path: '/userProfile',
        name: 'userProfile',
        component: () => import('components/userProfile'),
      },
      {
        path: '/GlobalParms',
        name: 'GlobalParms',
        component: () => import('components/GlobalParms'),
      },
      {
        path: '/SmtpMaint',
        name: 'SmtpMaint',
        component: () => import('components/SmtpMaint'),
      },
      {
        path: '/GuaranteeMaint',
        name: 'GuaranteeMaint',
        component: () => import('@/components/mtmng/GuaranteeMaint'),
      },
      {
        path: '/RejectMaint',
        name: 'RejectMaint',
        component: () => import('@/components/mtmng/RejectMaint'),
      },
      {
        path: '/RegisterMaintHist',
        name: 'RegisterMaintHist',
        component: () => import('@/components/mtmng/RegisterMaintHist'),
      },
      {
        path: '/BulkSMS',
        name: 'BulkSMS',
        component: () => import('@/components/mtmng/BulkSMS'),
      },
      {
        path: '/UserInformationMaint',
        name: 'UserInformationMaint',
        component: () => import('@/components/mtmng/UserInformationMaint'),
      },
      {
        path: '/JobApplicationMaint',
        name: 'JobApplicationMaint',
        component: () => import('@/components/mtmng/JobApplicationMaint'),
      },
      {
        path: '/ReceiveChannelMaint',
        name: 'ReceiveChannelMaint',
        component: () => import('@/components/mtmng/ReceiveChannelMaint'),
      },
      {
        path: '/ConvenientTimeMaint',
        name: 'ConvenientTimeMaint',
        component: () => import('@/components/mtmng/ConvenientTimeMaint'),
      },
      {
        path: '/CreditLimitMaint',
        name: 'CreditLimitMaint',
        component: () => import('@/components/mtmng/CreditLimitMaint'),
      },
      {
        path: '/BulkSMSHist',
        name: 'BulkSMSHist',
        component: () => import('@/components/mtmng/BulkSMSHist'),
      },
    ],
  },
  {
    path: '/Subscribe',
    name: 'subscribe',
    component: () => import('@/components/home/Subscribe'),
  },
  {
    path: '/workWithUs',
    name: 'workWithUs',
    component: () => import('@/components/home/workWithUs'),
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('@/components/home/About'),
  },
  {
    path: '/Goal',
    name: 'Goal',
    component: () => import('@/components/home/Goal'),
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: () => import('@/components/cookie-policy'),
  },
  {
    path: '/cookie-policy-private',
    name: 'cookie-policy-private',
    component: () => import('@/components/cookie-policy-private'),
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('@/components/home/Register'),
  },
  {
    path: '/Products',
    name: 'Products',
    component: () => import('@/components/home/Products'),
  },
  {
    path: '/Promotions',
    name: 'Promotions',
    component: () => import('@/components/home/Promotions'),
  },
  {
    path: '/Articles',
    name: 'Articles',
    component: () => import('@/components/home/Articles'),
  },
  {
    path: '/Product',
    name: 'Product',
    component: () => import('@/components/Product'),
    props: true,
  },
  {
    path: '/BranchGSB',
    name: 'BranchGSB',
    component: () => import('@/components/home/BranchGSB'),
    props: true,
  },
  {
    path: '/BranchTIP',
    name: 'BranchTIP',
    component: () => import('@/components/home/BranchTIP'),
    props: true,
  },
  {
    path: '/BranchBCP',
    name: 'BranchBCP',
    component: () => import('@/components/home/BranchBCP'),
    props: true,
  },
  {
    path: '/Article',
    name: 'Article',
    component: () => import('@/components/Article'),
    props: true,
  },
  {
    path: '/Cal',
    name: 'Cal',
    component: () => import('@/components/home/Cal'),
    props: true,
  },
  {
    path: '/Promotion',
    name: 'Promotion',
    component: () => import('@/components/Promotion'),
    props: true,
  },
  {
    path: '/DocumentDownload',
    name: 'DocumentDownload',
    component: () => import('@/components/home/DocumentDownload'),
    props: true,
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    props: (route) => ({
      prop1: route.query.prop1
    })
  },
  {
    path: '/OtpEmailExample',
    name: 'OtpEmailExample',
    component: () => import('@/components/Example/OtpEmailExample'),
  },
  {
    path: '/OtpSMSExample',
    name: 'OtpSMSExample',
    component: () => import('@/components/Example/OtpSMSExample'),
  },
]
