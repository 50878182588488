export default {
  ReceiveChannelTitle: 'จัดการช่องทางที่ได้รับข่าวสาร',
  buttonnewReceiveChannel: 'เพิ่มช่องทางที่ได้รับข่าวสาร',
  buttonnewReceiveChannelLanguage: 'เพิ่มภาษาช่องทางที่ได้รับข่าวสาร',
  buttonDeleteReceiveChannel: 'ลบช่องทางที่ได้รับข่าวสาร',
  buttonDeleteReceiveChannelLanguage: 'ลบภาษาช่องทางที่ได้รับข่าวสาร',
  newReceiveChannel: 'เพิ่มช่องทางที่ได้รับข่าวสาร',
  editReceiveChannel: 'แก้ไขช่องทางที่ได้รับข่าวสาร',
  newReceiveChannelLanguage: 'เพิ่มภาษาช่องทางที่ได้รับข่าวสาร',
  editReceiveChannelLanguage: 'แก้ไขภาษาช่องทางที่ได้รับข่าวสาร',
  receiveChannelName: 'ชื่อช่องทางที่ได้รับข่าวสาร',
  confirmDelete: 'คุณต้องการที่จะลบช่องทางที่ได้รับข่าวสาร {}?',
  confirmReceiveChannelLanguageDelete: 'คุณต้องการที่จะลบช่องทางที่ได้รับข่าวสารภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบช่องทางที่ได้รับข่าวสารที่เลือก?',
  confirmReceiveChannelLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาช่องทางที่ได้รับข่าวสารที่เลือก?',
  progressMessageDelete: 'กำลังลบช่องทางที่ได้รับข่าวสาร {}',
  progressMessageReceiveChannelLanguageDelete: 'กำลังลบภาษาช่องทางที่ได้รับข่าวสาร {}',
  messageDeleteNotSelected: 'กรุณาเลือกช่องทางที่ได้รับข่าวสารที่จะลบ',
  messagedeleteLanguageNotSelected: 'กรุณาเลือกภาษาช่องทางที่ได้รับข่าวสารที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  receiveChannelDetail: 'รายละเอียดช่องทางที่ได้รับข่าวสาร',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
}
