export default {
  SubscribeTitle: 'SubscribeTitle',
  buttonnewSubscribeTitle: 'New SubscribeTitle',
  buttonnewSubscribeTitleLanguage: 'New SubscribeTitle Language',
  buttonDeleteSubscribeTitle: 'Delete SubscribeTitle',
  buttonDeleteSubscribeTitleLanguage: 'Delete SubscribeTitle Language',
  newSubscribeTitle: 'New SubscribeTitle',
  editSubscribeTitle: 'Edit SubscribeTitle',
  newSubscribeTitleLanguage: 'New SubscribeTitle Language',
  editSubscribeTitleLanguage: 'Edit SubscribeTitle Language',
  SubscribeTitleName: 'SubscribeTitle Name',
  confirmDelete: 'Are you sure you want to delete SubscribeTitle {}?',
  confirmSubscribeTitleLanguageDelete: 'Are you sure you want to delete SubscribeTitle Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected SubscribeTitle?',
  confirmSubscribeTitleLanguageDeleteSelected: 'Are you sure you want to delete selected SubscribeTitle Language?',
  progressMessageDelete: 'Deleting SubscribeTitle {}',
  progressMessageSubscribeTitleLanguageDelete: 'Deleting SubscribeTitle Language {}',
  messageDeleteNotSelected: 'Please select SubscribeTitle to delete',
  messageDeleteSubscribeTitleLanguageNotSelected: 'Please select SubscribeTitle Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  SubscribeTitleDetail: 'SubscribeTitle Detail',
  copyHeader: 'Copy From Header',
  policyFlag: 'Accept Policy',
  subscribeText: 'Subscribe Message',
  subscribeHeader: 'Subscribe Header',
  name: 'Name',
  email: 'e-Mail',
  tel: 'Tel',
  messageName: 'Please Enter Name',
  messageEmail: 'Please Enter e-Mail',
  messageTel: 'Please Enter Tel',
  messageSubscribeMessage: 'Please Enter Subscribe Message',
  messageSubscribeHeaderMessage: 'Please Enter Subscribe Header Message',
  messagePolicy: 'Please Enter Policy',
}
