export default {
  CreditLimitTitle: 'จัดการวงเงินที่ต้องการ',
  buttonnewCreditLimit: 'เพิ่มวงเงินที่ต้องการ',
  buttonnewCreditLimitLanguage: 'เพิ่มภาษาวงเงินที่ต้องการ',
  buttonDeleteCreditLimit: 'ลบวงเงินที่ต้องการ',
  buttonDeleteCreditLimitLanguage: 'ลบภาษาวงเงินที่ต้องการ',
  newCreditLimit: 'เพิ่มวงเงินที่ต้องการ',
  editCreditLimit: 'แก้ไขวงเงินที่ต้องการ',
  newCreditLimitLanguage: 'เพิ่มภาษาวงเงินที่ต้องการ',
  editCreditLimitLanguage: 'แก้ไขภาษาวงเงินที่ต้องการ',
  creditLimitName: 'ชื่อวงเงินที่ต้องการ',
  confirmDelete: 'คุณต้องการที่จะลบวงเงินที่ต้องการ {}?',
  confirmCreditLimitLanguageDelete: 'คุณต้องการที่จะลบวงเงินที่ต้องการภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบวงเงินที่ต้องการที่เลือก?',
  confirmCreditLimitLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาวงเงินที่ต้องการที่เลือก?',
  progressMessageDelete: 'กำลังลบวงเงินที่ต้องการ {}',
  progressMessageCreditLimitLanguageDelete: 'กำลังลบภาษาวงเงินที่ต้องการ {}',
  messageDeleteNotSelected: 'กรุณาเลือกวงเงินที่ต้องการที่จะลบ',
  messagedeleteLanguageNotSelected: 'กรุณาเลือกภาษาวงเงินที่ต้องการที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  creditLimitDetail: 'รายละเอียดวงเงินที่ต้องการ',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  isIndividual: 'บุคคลธรรมดา',
  isCompany: 'นิติบุคคล',
  creditLimitMin: 'วงเงินเริ่มต้น',
  creditLimitMax: 'วงเงินสูงสุด',
  productName: 'ชื่อผลิตภัณฑ์'
}
