/**
* Created by vouill on 11/14/17.
*/

<template>
  <!-- <v-footer :fixed="fixed" app  color="blue darken-1" dark fixed  v-if="isProfileLoaded" class="pa-3">
    <v-layout class="justify-center">
      <span class="text-xs-center">&nbsp;Copyright &copy; 2020 All Rights Reserved by <br>&nbsp Innotech Laboratory Services Co.,Ltd.</span>
    </v-layout>
  </v-footer> -->
  <v-footer color="blue darken-1" dark fixed v-if="isProfileLoaded" class="pa-0">
    <v-layout class="justify-center">
      <span class="text-xs-center"><small>&nbsp;Copyright &copy; 2020 All Rights Reserved by <br>&nbsp Innotech Laboratory Services Co.,Ltd.</small></span>
    </v-layout>
  </v-footer>
</template>

<style scoped>
  .sq-footer {
    background-color: rgb(183, 72, 14);
    height: 20px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    color: white;
    align-items: center;
    padding-right: 1em;
  }
  a {
    color:white;
  }
</style>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { expireTimeJwt } from '@/utils'
  export default {
    data: () => ({
      fixed: '',
    }),
    name: 'sqreen-footer',
    computed: {
      ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded']),
      ...mapState({
        authLoading: state => state.auth.status === 'loading',
        name: state => `${state.user.profile.first_name} ${state.user.profile.last_name}`,
        picture_base64: state => `${state.user.profile.picture_base64}`,
        token: state => `${state.auth.token}`,
      }),
      tokenExpire: function () {
        return expireTimeJwt(this.token)
      }
    }
  }
</script>
