export default {
  DateReceivedFrom: 'Date Received From',
  DateReceivedTo: 'Date Received To',
  keyword: 'Key Word',
  HeaderSearching: 'Stock Management',
  Receive: 'Receive',
  Issue: 'Issue',
  productGroup: 'Product Group',
  vendor: 'Vendor',
  itemNo: 'Item No',
  itemName: 'Item Name',
  uom: 'UOM',
  stockroom: 'Stockroom',
  location: 'Location',
  docDate: 'Doc Date',
  ReceiveQTY: 'Receive QTY',
  LotNo: 'Lot No.',
  ExpireDate: 'Expire Date',
  ReasonCode: 'Reason',
  PleaseEnterQty: 'Please Enter Qty Moved.',
  PleaseEnterReason: 'Please Enter Reason',
  QtyMovedInvalid: 'Qty Moved Invalid',
  PleaseEnterExpireDate: 'Please Enter Expire Date',
  moveTicket: 'Move Ticket',
  MoveTicketNotFound: 'Move Ticket Not Found',
  ScanMoveTicket: 'Scan Move Ticket',
  ReceiveFromSupplier: 'Receive From Supplier',
  ScanPoNo: 'Scan PO No.',
  PoNotFound: 'PO Not Found',
  DocId: 'Doc Id',
  DocDate: 'Doc Date',
  Facility: 'Facility',
  FacilityName: 'Facility Name',
  Vendor: 'Vendor',
  VendorName: 'Vendor Name',
  InvoiceNo: 'Invoice No',
  Status: 'Status',
  Action: 'Action',
  PoType: 'Po Type',
  resourceNo: 'Resource No',
  resourceName: 'Resource Name',
  remark: 'Remark',
  menu1: 'สินเชื่อและบริการทางการเงิน',
  messageCookieLaw: 'บริษัท มีที่ มีเงิน จำกัด (“บริษัทฯ”) มีการใช้คุกกี้บนเว็บไซต์ของบริษัทฯ เพื่อช่วยให้ท่านได้รับประสบการณ์ที่ดีจากการใช้บริการ และช่วยให้บริษัทฯ สามารถพัฒนาท่านภาพของบริการให้ตอบสนองต่อความต้องการของท่านมากยิ่งขึ้น',
  messageCookieLaw2: 'เรียนรู้เกี่ยวกับคุกกี้ของเบราว์เซอร์เพิ่มเติม',
  messagePolicy: 'นโยบายคุ้มครองข้อมูลส่วนบุคคล',
  CookiePolicyHeader: 'ประกาศความเป็นส่วนตัว',
  CookiePolicyPrivateHeader: 'นโยบายการใช้คุกกี้',
  Accept: 'ยอมรับ',
  NameSurName: 'ชื่อ-นามสกุล',
  product: 'ผลิตภัณฑ์',
  Tel: 'เบอร์โทรศัพท์',
  provinces: 'จังหวัด',
  amphur: 'อำเภอ',
  tambon: 'ตำบล',
  RefCode: 'Ref Code',
  view: 'อ่าน',
  readMore: 'อ่านต่อ',
  aboutUsHeader: 'เกี่ยวกับเรา',
  aboutUs1: 'ธุรกิจที่เกี่ยวข้องกับเรื่องที่ดิน โดยเฉพาะเรื่องขายฝาก มักมีการคิดดอกเบี้ยที่สูงกว่าที่กฎหมายกำหนด และมีปัญหาการยึดที่ทำกินของประชาชน',
  aboutUs2: 'ปัญหาทางเศรษฐกิจที่เกิดขึ้นหลังจาก Covid-19 ประกอบกับภาวะเงินเฟ้อและหนี้ครัวเรือนที่เพิ่มสูงขึ้น ทำให้ผู้ประกอบการหรือประชาชนทั่วไป มีความต้องการเงินทุนที่มีอัตราดอกเบี้ยที่เหมาะสม และรวดเร็ว',
  aboutUs3: 'นโยบายภาครัฐ ที่ต้องการมุ่งเน้นการแก้ไขปัญหาหนี้สินภาคประชาชน โดยสินเชื่อที่ดินและขายฝาก ถือเป็น 1 ในปัญหาดังกล่าว',
  aboutUs4: 'การต่อยอดการเป็นธนาคารเพื่อสังคมของธนาคารออมสิน ที่มุ่งเน้นการส่งเสริมการเข้าถึงแหล่งเงินทุน ที่คิดดอกเบี้ยที่เหมาะสมและเป็นธรรม สามารถสร้างผลกระทบเชิงบวกต่อธุรกิจ หลังจากธุรกิจสินเชื่อจำนำทะเบียนรถก่อนหน้านี้',
  branch: 'ค้นหาสาขา',
  Articles: 'ข่าวสารและบทความ',
  promotions: 'โปรโมชั่น',
  Subscribe: 'ติดต่อเรา',
  workWithUs: 'ร่วมงานกับเรา',
  email: 'อีเมล',
  message: 'ข้อความ',
  applicationForm: 'สมัครสินเชื่อ ',
  send: 'ส่ง',
  GoalHeader: 'วิสัยทัศน์และพันธกิจ',
  feature: 'คุณสมบัติผู้ขอสินเชื่อ',
  objective: 'วัตถุประสงค์',
  fee: 'ค่าธรรมเนียม',
  BranchGSBHeader: 'สาขาธนาคารออมสิน',
  BranchTIPHeader: 'สาขาทิพยประกันภัย',
  BranchBCPHeader: 'สาขาบางจาก',
  FindBranch: 'ค้นหาสาขาใกล้คุณ',
  FindBranchKey: 'พิมพ์คำค้นหาหรือรหัสไปรษณีย์',
  RegisterButton: 'สมัคร',
  AcceptPolicy: 'ยอมรับ นโยบายข้อมูลส่วนบุคคล',
  RejectPolicy: 'ไม่ยอมรับ นโยบายข้อมูลส่วนบุคคล',
  Etc: 'อื่นๆ',
  History: 'ความเป็นมา',
  employee: 'สำหรับ พนักงาน/พันธมิตร',
  PHONE: 'โทรศัพท์',
  FACEBOOK: 'FACEBOOK',
  LINE: 'LINE',
  ADDRESS: 'ที่อยู่',
  ADDRESSDetail: '63/2 อาคาร บริษัท ทิพยประกันภัย จำกัด (มหาชน) ชั้น 2 ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพฯ 10310',
  EMAIL: 'อีเมล',
  viewMap: 'ดูแผนที่',
  FAQ: 'คำถามที่พบบ่อย',
  Question1: 'Q: บริษัท มีที่ มีเงิน จำกัด ตั้งอยู่ที่ไหน?',
  Answer1: 'A: 63/2 อาคาร บริษัท ทิพยประกันภัย จำกัด (มหาชน) ชั้น 2 ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพฯ 10310',
  Question2: 'Q: เวลาเปิดและปิดทำการของสำนักงาน?',
  Answer2: 'A: เวลาเปิดและปิดทำการ 8.30 – 17.30 น.',
  Question3: 'Q: ผลิตภัณฑ์มีอะไรบ้างบ้าง?',
  Answer3: 'A: สินเชื่อที่ดิน',
  CalHeader: 'เครื่องมือช่วยคำนวณเบื้องต้น',
  GovernmentPrice: 'ราคาราชการ',
  MarketPrice: 'ราคาตลาด',
  calButton: 'คำนวณ',
  GovernmentCreditLimit: 'วงเงินสูงสุด',
  MarketCreditLimit: 'วงเงินสูงสุด',
  messageGovernment: 'กรุณากรอกราคาราชการ',
  messageMarket: 'กรุณากรอกราคาตลาด',
  DocumentDownload: 'ดาวน์โหลดเอกสาร',
  Home: 'หน้าแรก',
  InterestedPleaseContact: 'สนใจโปรดติดต่อ',
  JobPosition: 'ตำแหน่งที่ว่างอยู่',
  eduHist: 'ประวัติการศึกษา',
  workHist: 'ประวัติการทำงาน (ปี)',
  JobApplication: 'ตำแหน่งที่ต้องการสมัคร',
  DesiredSalary: 'เงินเดือนที่ต้องการ',
  fileImageName: 'แนบรูปถ่าย',
  fileHist: 'แนบประวัติ',
  birthDay: 'วันเกิด',
  firstName: 'ชื่อ',
  lastName: 'นามสกุล',
  FormJobApplication: 'แบบฟอร์มสมัครงาน',
  messageMandatory: 'กรุณากรอกข้อความในช่องที่มีเครื่องหมายดอกจันสีแดง',
  messageFirstName: 'กรุณากรอกชื่อ',
  messageLastName: 'กรุณากรอกนามสกุล',
  messageAddr: 'กรุณากรอกที่อยู่',
  messageTel: 'กรุณากรอกเบอร์ติดต่อ',
  messageEmail: 'กรุณากรอกอีเมล',
  messageEduHist: 'กรุณากรอกประวัติการศึกษา',
  messageWorkHist: 'กรุณากรอกประวัติการทำงาน',
  messageTelIncorrect: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
  messageEmailIncorrect: 'อีเมลไม่ถูกต้อง',
  JobApplicationTitle: 'จัดการข้อมูลสมัครงาน',
  downloadFileResume: 'Download Resume',
  policyFlag: 'ยอมรับนโยบายส่วนบุคคล',
}
