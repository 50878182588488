export default {
  BranchBcpTitle: 'จัดการสาขาบางจาก',
  buttonNewBranchBcp: 'เพิ่มสาขาบางจาก',
  buttonNewBranchBcpLanguage: 'เพิ่มภาษาสาขาบางจาก',
  buttonDeleteBranchBcp: 'ลบสาขาบางจาก',
  buttonDeleteBranchBcpLanguage: 'ลบภาษาสาขาบางจาก',
  newBranchBcp: 'เพิ่มสาขาบางจาก',
  editBranchBcp: 'แก้ไขสาขาบางจาก',
  newBranchBcpLanguage: 'เพิ่มภาษาสาขาบางจาก',
  editBranchBcpLanguage: 'แก้ไขภาษาสาขาบางจาก',
  name: 'ชื่อสาขาบางจาก',
  confirmDelete: 'คุณต้องการที่จะลบสาขาบางจาก {}?',
  confirmBranchBcpLanguageDelete: 'คุณต้องการที่จะลบสาขาบางจากภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบสาขาบางจากที่เลือก?',
  confirmBranchBcpLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาสาขาบางจากที่เลือก?',
  progressMessageDelete: 'กำลังลบสาขาบางจาก {}',
  progressMessageBranchBcpLanguageDelete: 'กำลังลบภาษาสาขาบางจาก {}',
  messageDeleteNotSelected: 'กรุณาเลือกสาขาบางจากที่จะลบ',
  messageDeleteBranchBcpLanguageNotSelected: 'กรุณาเลือกภาษาสาขาบางจากที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  BranchBcpDetail: 'รายละเอียดสาขาบางจาก',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  street1: 'ที่อยู่',
  tel: 'เบอร์โทรศัพท์',
}
