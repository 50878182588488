export default {
  WorkWithUsTitle: 'Work With Us',
  buttonNewWorkWithUs: 'New Work With Us',
  buttonNewWorkWithUsLanguage: 'New Work With Us Language',
  buttonDeleteWorkWithUs: 'Delete Work With Us',
  buttonDeleteWorkWithUsLanguage: 'Delete Work With Us Language',
  newWorkWithUs: 'New Work With Us',
  editWorkWithUs: 'Edit Work With Us',
  newWorkWithUsLanguage: 'New Work With Us Language',
  editWorkWithUsLanguage: 'Edit Work With Us Language',
  name: 'Work With Us Name',
  confirmDelete: 'Are you sure you want to delete Work With Us {}?',
  confirmWorkWithUsLanguageDelete: 'Are you sure you want to delete Work With Us Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Work With Us?',
  confirmWorkWithUsLanguageDeleteSelected: 'Are you sure you want to delete selected Work With Us Language?',
  progressMessageDelete: 'Deleting Work With Us {}',
  progressMessageWorkWithUsLanguageDelete: 'Deleting Work With Us Language {}',
  messageDeleteNotSelected: 'Please select Work With Us to delete',
  messageDeleteWorkWithUsLanguageNotSelected: 'Please select Work With Us Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  WorkWithUsDetail: 'Work With Us Detail',
  copyHeader: 'Copy From Header',
  description1: 'Description',
  tel: 'Tel.',
  messagePleaseEnterWorkWithUsName: 'Please Enter Name',
  messagePleaseEnterWorkWithUsDetail: 'Please Enter Detail',
  JobApplicationTitle: 'Job Application Maint',
}
