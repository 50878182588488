export default {
  nodeTitle: 'Node',
  buttonNewNode: 'เพิ่ม Node',
  buttonNewNodeLanguage: 'เพิ่ม Node Language',
  buttonDeleteNode: 'ลบ Node',
  buttonDeleteNodeLanguage: 'ลบ Node Language',
  newNode: 'เพิ่ม Node',
  editNode: 'แก้ไข Node',
  newNodeLanguage: 'เพิ่ม Node ภาษา',
  editNodeLanguage: 'แก้ไข Node ภาษา',
  nodeType: 'ชนิด Node',
  nodeWindow: 'หน้าต่าง',
  nodeName: 'ชื่อ Node',
  nodeComment: 'อธิบาย Node',
  confirmDelete: 'คุณต้องการที่จะลบ Node {}?',
  confirmNodeLanguageDelete: 'คุณต้องการที่จะลบ Node Language {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบ Node ที่เลือก?',
  confirmNodeLanguageDeleteSelected: 'คุณต้องการที่จะลบ Node Language ที่เลือก?',
  progressMessageDelete: 'กำลังลบ Node {}',
  progressMessageNodeLanguageDelete: 'กำลังลบ Node Language {}',
  messageDeleteNotSelected: 'กรุณาเลือก Node ที่จะลบ',
  messageDeleteNodeLanguageNotSelected: 'กรุณาเลือก Node Language ที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
}
