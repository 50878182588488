export default {
  BranchTipTitle: 'Branch TIP',
  buttonNewBranchTip: 'New Branch TIP',
  buttonNewBranchTipLanguage: 'New Branch TIP Language',
  buttonDeleteBranchTip: 'Delete Branch TIP',
  buttonDeleteBranchTipLanguage: 'Delete Branch TIP Language',
  newBranchTip: 'New Branch TIP',
  editBranchTip: 'Edit Branch TIP',
  newBranchTipLanguage: 'New Branch TIP Language',
  editBranchTipLanguage: 'Edit Branch TIP Language',
  name: 'Branch TIP Name',
  confirmDelete: 'Are you sure you want to delete Branch TIP {}?',
  confirmBranchTipLanguageDelete: 'Are you sure you want to delete Branch TIP Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Branch TIP?',
  confirmBranchTipLanguageDeleteSelected: 'Are you sure you want to delete selected Branch TIP Language?',
  progressMessageDelete: 'Deleting Branch TIP {}',
  progressMessageBranchTipLanguageDelete: 'Deleting Branch TIP Language {}',
  messageDeleteNotSelected: 'Please select Branch TIP to delete',
  messageDeleteBranchTipLanguageNotSelected: 'Please select Branch TIP Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  BranchTipDetail: 'Branch TIP Detail',
  copyHeader: 'Copy From Header',
  street1: 'Address',
  tel: 'Tel.',
}
