export default {
  windowTitle: 'Window',
  buttonNewWindow: 'New Window',
  buttonDeleteWindow: 'Delete Window',
  newWindow: 'New Window',
  editWindow: 'Edit Window',
  windowName: 'Window Name',
  windowComment: 'Window Comment',
  confirmDelete: 'Are you sure you want to delete Window {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Window?',
  progressMessageDelete: 'Deleting Window {}',
  messageDeleteNotSelected: 'Please select window to delete',
}
