export default {
  ArticlesTitle: 'จัดการข่าวและบทความ',
  buttonnewArticles: 'เพิ่มบทความ',
  buttonnewArticlesLanguage: 'เพิ่มภาษาบทความ',
  buttonDeleteArticles: 'ลบบทความ',
  buttonDeleteArticlesLanguage: 'ลบภาษาบทความ',
  newArticles: 'เพิ่มบทความ',
  editArticles: 'แก้ไขบทความ',
  newArticlesLanguage: 'เพิ่มภาษาบทความ',
  editArticlesLanguage: 'แก้ไขภาษาบทความ',
  articlesName: 'ชื่อบทความ',
  confirmDelete: 'คุณต้องการที่จะลบบทความ {}?',
  confirmArticlesLanguageDelete: 'คุณต้องการที่จะลบบทความภาษา {}?',
  confirmDeleteSelected: 'คุณต้องการที่จะลบบทความที่เลือก?',
  confirmArticlesLanguageDeleteSelected: 'คุณต้องการที่จะลบภาษาบทความที่เลือก?',
  progressMessageDelete: 'กำลังลบบทความ {}',
  progressMessageArticlesLanguageDelete: 'กำลังลบภาษาบทความ {}',
  messageDeleteNotSelected: 'กรุณาเลือกบทความที่จะลบ',
  messageDeleteArticlesLanguageNotSelected: 'กรุณาเลือกภาษาบทความที่จะลบ',
  tabWindow: 'หน้าต่าง',
  tabLanguage: 'ภาษา',
  AddPicture: 'เพิ่มรูป',
  articlesDetail: 'รายละเอียดบทความ',
  copyHeader: 'คัดลอกข้อมูลส่วนหัว',
  messagePleaseEnterArticleName: 'กรุณากรอกชื่อบทความ',
  messagePleaseEnterArticleDetail: 'กรุณากรอกรายละเอียดบทความ',
}
