export default {
  GuaranteeTitle: 'Guarantee',
  buttonnewGuarantee: 'New Guarantee',
  buttonnewGuaranteeLanguage: 'New Guarantee Language',
  buttonDeleteGuarantee: 'Delete Guarantee',
  buttonDeleteGuaranteeLanguage: 'Delete Guarantee Language',
  newGuarantee: 'New Guarantee',
  editGuarantee: 'Edit Guarantee',
  newGuaranteeLanguage: 'New Guarantee Language',
  editGuaranteeLanguage: 'Edit Guarantee Language',
  guaranteeName: 'Guarantee Name',
  confirmDelete: 'Are you sure you want to delete Guarantee {}?',
  confirmGuaranteeLanguageDelete: 'Are you sure you want to delete Guarantee Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Guarantee?',
  confirmGuaranteeLanguageDeleteSelected: 'Are you sure you want to delete selected Guarantee Language?',
  progressMessageDelete: 'Deleting Guarantee {}',
  progressMessageGuaranteeLanguageDelete: 'Deleting Guarantee Language {}',
  messageDeleteNotSelected: 'Please select Guarantee to delete',
  messageDeleteLanguageNotSelected: 'Please select Guarantee Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  guaranteeDetail: 'Guarantee Detail',
  copyHeader: 'Copy From Header',
}
