export default {
  newProductDetail: 'Product Detail',
  newProductDetailLanguage: 'Product Detail Language',
  productMaintTitle: 'Product Maintenance',
  productDetailMaintTitle: 'Product Detail Maintenance',
  productName: 'Product Name',
  productDetailName: 'Product Detail Name',
  productDetailType: 'Product Detail Type',
  productDetailLanguage: 'Product Detail Language',
  buttonNew: 'New Detail',
  buttonNewLanguage: 'New Product Detail Language',
  buttonDeleteLanguage: 'Delete Product Detail Language',
  buttonDelete: 'Delete Detail',
  buttonExpand: 'Expand',
  buttonCollapse: 'Collapse',
  buttonFind: 'Find',
  text: 'Text',
  image: 'Image',
  confirmDelete: 'Do you want to delete Product Detail ?',
  messageDeleteProductLanguageNotSelected: 'Please select Product Language to delete !',
  AddPicture: 'Add Picture',
  buttonNewProduct: 'New Product',
  buttonDeleteProduct: 'Delete Product',
  buttonEditProduct: 'Edit Product'
}
