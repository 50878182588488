export default {
  UploadTitle: 'Upload',
  buttonnewUpload: 'New Upload',
  buttonnewUploadLanguage: 'New Upload Language',
  buttonDeleteUpload: 'Delete Upload',
  buttonDeleteUploadLanguage: 'Delete Upload Language',
  newUpload: 'New Upload',
  editUpload: 'Edit Upload',
  newUploadLanguage: 'New Upload Language',
  editUploadLanguage: 'Edit Upload Language',
  uploadName: 'Upload Name',
  confirmDelete: 'Are you sure you want to delete Upload {}?',
  confirmUploadLanguageDelete: 'Are you sure you want to delete Upload Language {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Upload?',
  confirmUploadLanguageDeleteSelected: 'Are you sure you want to delete selected Upload Language?',
  progressMessageDelete: 'Deleting Upload {}',
  progressMessageUploadLanguageDelete: 'Deleting Upload Language {}',
  messageDeleteNotSelected: 'Please select Upload to delete',
  messageDeleteUploadLanguageNotSelected: 'Please select Upload Language to delete',
  tabWindow: 'Window',
  tabLanguage: 'Language',
  AddPicture: 'Add Picture',
  uploadDetail: 'Upload Detail',
  copyHeader: 'Copy From Header',
}
