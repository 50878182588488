export default {
  Title: 'Lab Order Item',
  buttonBrowse: 'Browse Order Item',
  buttonDelete: 'Delete Order Item',
  newWindow: 'New Order Item',
  editWindow: 'Edit Order Item',
  orderItemIDHeader: 'Order Item ID',
  orderItemDescHeader: 'Order Item Desc',
  activeHeader: 'Active',
  cancelHeader: 'Cancel',
  deleteHeader: 'Delete',
  noteHeader: 'Note',
  confirmDelete: 'Are you sure you want to delete Window {}?',
  confirmDeleteSelected: 'Are you sure you want to delete selected Window?',
  progressMessageDelete: 'Deleting Window {}',
  messageDeleteNotSelected: 'Please select window to delete',
}
